export const getNewRateByAdjustedDownPayment = (
  newDownPayment,
  downPayment,
  rate,
  termOfContract
) => {
  const relativeDownPayment = newDownPayment - downPayment
  return rate - relativeDownPayment / termOfContract
}

export const getMaxDownPayment = (
  downPayment,
  rate,
  termOfContract,
  minRate
) => {
  return (
    Math.floor(((rate - minRate) * termOfContract) / 100) * 100 + downPayment
  )
}

export default {
  getNewRateByAdjustedDownPayment,
  getMaxDownPayment
}
