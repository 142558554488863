var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"am-vehicle-player-navigation"},[_c('div',[_c('button',{class:{
        'am-vehicle-player-navigation__control': true,
        'am-vehicle-player-navigation__control--disabled': !_vm.hasPreviousItem
      },attrs:{"aria-label":_vm.navigateBackwardLabel,"disabled":!_vm.hasPreviousItem},on:{"click":_vm.onClickPrevious}},[_c('amui-icon',{attrs:{"name":"arrow-slide-left"}})],1)]),_c('div',[(_vm.availableTypes.includes('gallery'))?_c('button',{class:{
        'am-vehicle-player-navigation__control': true,
        'am-vehicle-player-navigation__control--inactive':
          _vm.itemType !== 'gallery'
      },attrs:{"aria-label":_vm.navigateToGalleryLabel},on:{"click":_vm.onClickGallery}},[_c('div',{staticClass:"am-vehicle-player-navigation__image-counter"},[_c('amui-icon',{attrs:{"name":"image"}}),_c('span',[_vm._v(_vm._s(_vm.currentImageNumber)+"/"+_vm._s(_vm.totalImagesCount))])],1)]):_vm._e(),(_vm.availableTypes.includes('rotation'))?_c('button',{class:{
        'am-vehicle-player-navigation__control': true,
        'am-vehicle-player-navigation__control--inactive':
          _vm.itemType !== 'rotation'
      },attrs:{"aria-label":_vm.navigateToRotationLabel},on:{"click":_vm.onClickRotation}},[_c('amui-icon',{attrs:{"name":"360-rotation"}})],1):_vm._e(),(_vm.availableTypes.includes('interior'))?_c('button',{class:{
        'am-vehicle-player-navigation__control': true,
        'am-vehicle-player-navigation__control--inactive':
          _vm.itemType !== 'interior'
      },attrs:{"aria-label":_vm.navigateToInteriorLabel},on:{"click":_vm.onClickInterior}},[_c('amui-icon',{attrs:{"name":"360-interior"}})],1):_vm._e()]),_c('div',[_c('button',{class:{
        'am-vehicle-player-navigation__control': true,
        'am-vehicle-player-navigation__control--disabled': !_vm.hasNextItem
      },attrs:{"aria-label":_vm.navigateForwardLabel,"disabled":!_vm.hasNextItem},on:{"click":_vm.onClickNext}},[_c('amui-icon',{attrs:{"name":"arrow-slide-right"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }