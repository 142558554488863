<template>
  <form class="am-checkout-personal-data" autocomplete="on">
    <div>
      <amui-chip
        :label="stepNumber + '/' + totalStepsNumber"
        type="secondary"
      />
    </div>
    <amui-headline
      :text="$t('checkout.personalData.headline')"
      html-tag="div"
      looks-like="h5"
    />
    <div>
      <amui-select
        :label="$t('checkout.personalData.salutation.label')"
        v-model="formData.salutation"
        :options="salutationOptions"
        :invalid="invalid.salutation"
        viewport="s"
        autocomplete="honorific-prefix"
        @change="onChangeSalutation"
      ></amui-select>
    </div>
    <div class="am-checkout-personal-data__row">
      <div>
        <amui-input
          v-model="formData.firstName"
          :label="$t('checkout.personalData.firstName.label')"
          :invalid="invalid.firstName"
          viewport="s"
          autocomplete="given-name"
          @input="onInputFirstName"
        />
      </div>
      <div>
        <amui-input
          v-model="formData.lastName"
          :label="$t('checkout.personalData.lastName.label')"
          :invalid="invalid.lastName"
          viewport="s"
          autocomplete="family-name"
          @input="onInputLastName"
        />
      </div>
    </div>
    <div>
      <amui-input
        v-model="formData.zipCode"
        :label="$t('checkout.personalData.zipCode.label')"
        :invalid="invalid.zipCode"
        viewport="s"
        autocomplete="postal-code"
        @keydown="onKeyPressZipCode"
        @paste="onPasteZipCode"
        @input="onInputZipCode"
      />
    </div>
  </form>
</template>

<script>
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiSelect } from '@/../ui/components/select'
import { AmuiInput } from '@/../ui/components/input'
import { AmuiChip } from '@/../ui/components/chip'
import {
  onPasteZipCode,
  onKeyPressZipCode,
  isValideZipcode
} from '@/app/utils/form-helper.js'

export default {
  name: 'AmCheckoutPersonalData',

  components: {
    AmuiHeadline,
    AmuiSelect,
    AmuiInput,
    AmuiChip
  },

  props: {
    stepNumber: {
      type: Number,
      required: true
    },
    totalStepsNumber: {
      type: Number,
      required: true
    },
    salutation: {
      type: String,
      required: false,
      default: null
    },
    firstName: {
      type: String,
      required: false,
      default: ''
    },
    lastName: {
      type: String,
      required: false,
      default: ''
    },
    zipCode: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      formData: {
        salutation: this.salutation,
        firstName: this.firstName,
        lastName: this.lastName,
        zipCode: this.zipCode
      },
      invalid: {
        salutation: false,
        firstName: false,
        lastName: false,
        zipCode: false
      },
      salutationOptions: [
        {
          label: 'Frau',
          value: 'FEMALE'
        },
        {
          label: 'Herr',
          value: 'MALE'
        }
      ]
    }
  },

  methods: {
    submit() {
      if (this.validate()) {
        this.$emit('save', Object.assign({}, this.formData))
      } else {
        this.$emit('error')
      }
    },
    validate() {
      this.invalid.salutation = this.formData.salutation === null
      this.invalid.firstName = this.formData.firstName.length === 0
      this.invalid.lastName = this.formData.lastName.length === 0
      this.invalid.zipCode = !isValideZipcode(this.formData.zipCode)

      return !Object.keys(this.invalid).some(key => this.invalid[key])
    },
    onChangeSalutation() {
      this.$emit('update:salutation', this.formData.salutation)
      this.invalid.salutation = false
    },
    onInputFirstName() {
      this.$emit('update:firstName', this.formData.firstName)
      this.invalid.firstName = false
    },
    onInputLastName() {
      this.$emit('update:lastName', this.formData.lastName)
      this.invalid.lastName = false
    },
    onInputZipCode() {
      this.$emit('update:zipCode', this.formData.zipCode)
      this.invalid.zipCode = false
    },
    onPasteZipCode: onPasteZipCode,
    onKeyPressZipCode: onKeyPressZipCode
  }
}
</script>
