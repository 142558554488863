import { formatPrice } from '../../../../../utils/formatter'

export default {
  computed: {
    seoTitle() {
      let titles = {
        leasing:
          '<make> <model> | ab <smallestLeasingRate> € mtl. | <dealerName>',
        financing:
          '<make> <model> | ab <smallestFinancingRate> € mtl. | <dealerName>',
        purchase: '<make> <model> | <price> € | <dealerName>'
      }
      return this.replaceTags(titles)
    },
    seoDescription() {
      let descriptions = {
        leasing:
          'Volltreffer! ▷ Jetzt den <make> <model> entdecken! ▷ <condition> <firstRegistration> ▷ Leasing ab <smallestLeasingRate> € monatlich ▷ Entdecken Sie auch günstige Finanzierung Angebote',
        financing:
          'Volltreffer! ▷ Jetzt den <make> <model> entdecken! ▷ <condition> <firstRegistration> ▷ Finanzierung ab <smallestFinancingRate> € monatlich ▷ Entdecken Sie auch günstige Leasing Angebote',
        purchase:
          'Volltreffer! ▷ Jetzt den <make> <model> entdecken! ▷ <condition> <firstRegistration> ▷ Nur <price>,- € ▷ Zu attraktiven Konditionen finanzieren ▷ Entdecken Sie auch günstige Leasing Angebote'
      }
      return this.replaceTags(descriptions)
    }
  },
  methods: {
    replaceTags: function(texts) {
      let text = ''

      if (this.rawVehicleData && this.rawDealerData) {
        const leasingOffer = this.pricing.leasing.offer.origin
        const financingOffer = this.pricing.financing.offer.origin
        const purchaseOffer = this.pricing.price.offer.origin

        const vehicleClass = this.rawVehicleData.vehicleClass
        const make = this.rawVehicleData.make
        const model = this.rawVehicleData.model
        const condition = this.rawVehicleData.condition
        const firstRegistration = this.rawVehicleData.firstRegistration
        const category = this.rawVehicleData.category
        const location = this.rawDealerData.address.city
        const dealer = this.rawDealerData.name

        text = texts[this.selectedPaymentMethod] || ''
        text = text.replace('<make>', make)
        text = text.replace('<model>', model)

        if (leasingOffer !== null) {
          text = text.replace(
            '<smallestLeasingRate>',
            formatPrice(leasingOffer.grossRate)
          )
        }

        if (financingOffer !== null) {
          text = text.replace(
            '<smallestFinancingRate>',
            formatPrice(financingOffer.monthlyInstallment)
          )
        }

        if (purchaseOffer !== null) {
          text = text.replace(
            '<price>',
            formatPrice(purchaseOffer.consumerPriceGross)
          )

          text = text.replace(
            '<vat>',
            parseInt(purchaseOffer.vatRate) > 0 ? 'inkl. MwSt' : 'ohne MwSt'
          )
        }

        text = text.replace(
          '<vehicleType>',
          this.$t('vehicleClass.' + vehicleClass + '.category' + category)
        )
        text = text.replace('<location>', location)
        text = text.replace('<dealerName>', dealer)
        text = text.replace(
          '<condition>',
          this.$t('vehicleClass.' + vehicleClass + '.condition' + condition)
        )
        text = text.replace(
          '<firstRegistration>',
          condition === 'USED'
            ? '| EZ ' +
                this.$t('vehicle.firstRegistrationTEXT', {
                  year: firstRegistration.substring(0, 4),
                  month: firstRegistration.substring(4, 6)
                })
            : ''
        )
      }
      return text
    }
  }
}
