<template>
  <div class="amui-crossed-out-price">
    <s>
      <slot>
        <span>{{ price }}</span>
      </slot>
    </s>
    <span v-if="suffix"> {{ suffix }}</span>
    <sup v-if="referenceSymbol"> {{ referenceSymbol }}</sup>
  </div>
</template>

<script>
export default {
  name: 'AmuiCrossedOutPrice',

  props: {
    price: {
      type: String,
      required: true
    },
    suffix: {
      type: String,
      required: false,
      default: null
    },
    referenceSymbol: {
      type: [String, Number],
      required: false,
      default: null
    }
  }
}
</script>
