<template>
  <div class="am-checkout-success">
    <div>
      <div class="am-checkout-success__vehicle">
        <div>
          <div v-if="sources.length" class="am-checkout-success__vehicle-image">
            <amui-image
              :sources="sources"
              :fallback-source="fallbackSource"
              :alt="vehicleTitle"
              ratio="3:2"
              object-fit="contain"
            />
          </div>
        </div>
        <div class="am-checkout-success__vehicle-details">
          <div>{{ vehicleTitle }}</div>
          <div>
            {{ vehiclePriceLabel }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <amui-headline
        :text="$t('checkout.success.headline')"
        html-tag="div"
        looks-like="h5"
      />
      <amui-headline
        v-if="useAutouncle"
        :text="$t('checkout.success.autouncle.headline')"
        html-tag="div"
        looks-like="h5"
      />
    </div>
    <div v-if="useAutouncle" class="am-checkout-success__autouncle">
      <div>{{ $t('checkout.success.autouncle.partner') }}</div>
      <svg
        width="158"
        height="16"
        viewBox="0 0 158 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_6949_154169)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M91.4562 0.366762V9.29308C91.4562 10.1066 91.5151 10.9402 91.9126 11.5617H91.9109C92.239 12.0677 92.7335 12.4437 93.3088 12.6244V15.7571C87.5534 15.3824 87.4173 11.4266 87.4141 9.06192L87.4141 8.91918L87.4326 0.366762H91.4562ZM101.139 0.365078V9.0775C101.139 10.7449 100.92 12.5571 100.022 13.6636C98.9105 15.0817 97.0494 15.6038 95.4242 15.7554V12.6514C97.0006 12.136 97.2482 10.4249 97.2482 9.16339V0.365078H101.139ZM126.277 0.0888672C128.613 0.0888672 130.388 0.673288 131.525 1.92634C132.309 2.80915 132.735 3.95428 132.718 5.13476H128.61C128.428 3.61897 127.411 3.04803 126.274 3.04803C123.859 3.04803 123.119 5.36381 123.119 8.11918C123.119 9.03876 123.14 12.835 126.233 12.835C128.347 12.835 128.568 11.2316 128.647 10.6455H132.798C132.777 15.3613 128.189 15.7537 126.313 15.7537C124.918 15.7537 122.439 15.5061 120.847 13.7529C119.256 11.9996 118.995 9.60297 118.995 8.03666C118.995 5.97182 119.453 3.90697 120.812 2.30024C122.286 0.548657 124.18 0.0888672 126.277 0.0888672ZM108.962 0.27413V3.37813C107.384 3.89518 107.138 5.60803 107.138 6.8695V15.6644H103.251V6.95203C103.251 5.28466 103.47 3.47245 104.366 2.3676H104.362C105.476 0.939394 107.333 0.427394 108.962 0.27413ZM111.072 0.272446C116.947 0.654762 116.967 4.76929 116.967 7.10866L116.948 15.6628H112.932V6.73645C112.932 5.92466 112.873 5.09266 112.475 4.4695C112.146 3.96483 111.653 3.58876 111.079 3.40508V0.27413L111.072 0.272446ZM157.787 0.457709V3.33939H150.857V6.46697H157.383V9.34697H150.857V12.5588H157.947V15.4809H146.868L146.867 0.457709H157.787ZM138.798 0.365078V12.4358H145.284V15.3899H134.732V0.365078H138.798Z"
            fill="#12ACDB"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M78.4705 0C84.2675 0 85.8389 4.32168 85.8389 7.99663C85.8389 14.0093 81.7328 15.8484 78.2684 15.8484C74.4048 15.8484 70.8006 13.6354 70.8006 8.01684C70.8006 7.00632 70.969 4.00842 72.793 2.08842C74.6254 0.166737 77.4027 0 78.4705 0ZM55.6747 0.365474V9.0779C55.6747 10.7453 55.4557 12.5575 54.5581 13.664C53.4465 15.0821 51.5871 15.6042 49.9585 15.7558V12.6518C51.5399 12.1364 51.7842 10.4253 51.7842 9.16379V0.365474H55.6747ZM45.9922 0.367158V9.29347C45.9922 10.1069 46.0511 10.9406 46.4486 11.5621H46.4553C46.7844 12.0678 47.2794 12.4437 47.8549 12.6248V15.7558C41.9837 15.3735 41.9602 11.2623 41.9602 8.9179L41.977 0.367158H45.9922ZM70.361 0.365474V3.34316H65.8355V15.3903H61.7732V3.34316H57.3471V0.365474H70.361ZM35.7892 0.365474L41.5155 15.3886H36.9682L35.9576 12.3739H30.1707L29.1871 15.3886H25.1602L30.8865 0.365474H35.7892ZM78.3391 3.11411C75.6612 3.11411 74.9336 5.50568 74.9336 8.10779C74.9336 10.5213 75.6225 12.9162 78.3172 12.9162L78.3189 12.9179C81.2208 12.9179 81.7042 10.1474 81.7042 8.04716C81.7042 5.94695 81.2208 4.87747 80.7745 4.20547C80.1917 3.36674 79.2334 3.11411 78.3391 3.11411ZM33.2191 3.29768H33.1602L31.1391 9.61853H35.097L33.2191 3.29768Z"
            fill="#83BB2D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.4054 0.347656C15.8551 0.347656 22.4522 3.76323 22.4522 11.3893C22.4522 11.9704 22.5869 15.4415 19.6867 15.4315C16.3301 15.4315 15.5823 12.9051 11.4054 12.9051C7.27744 12.9051 6.4808 15.4315 3.12417 15.4315C0.281967 15.4315 0.356141 12.1026 0.360245 11.4273L0.360384 11.3893C0.360384 3.76829 6.95575 0.347656 11.4054 0.347656ZM3.33807 9.86176C1.42144 9.86176 1.42144 12.7367 3.33807 12.7367C5.2547 12.7367 5.2547 9.86176 3.33807 9.86176ZM19.4644 9.86008C17.5478 9.86008 17.5478 12.735 19.4644 12.735L19.4728 12.7367C21.3894 12.7367 21.381 9.86008 19.4644 9.86008ZM11.4054 2.35524C8.65175 2.35524 6.27365 3.73629 5.15701 5.73545C4.49007 6.92618 5.51407 8.30724 7.05344 8.30724H15.7591C17.2968 8.30724 18.3191 6.92618 17.6539 5.73545C16.5372 3.73629 14.1591 2.35524 11.4054 2.35524Z"
            fill="#12ACDB"
          />
        </g>
        <defs>
          <clipPath id="clip0_6949_154169">
            <rect
              width="157.642"
              height="16"
              fill="white"
              transform="translate(0.359375)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiImage } from '@/../ui/components/image'

export default {
  name: 'AmCheckoutSuccess',

  components: {
    AmuiHeadline,
    AmuiImage
  },

  props: {
    sources: {
      type: Array,
      required: true,
      validator(sources) {
        return sources.every(
          source => 'media' in source && 'srcset' in source && source.srcset
        )
      }
    },
    fallbackSource: {
      type: String,
      required: false,
      default: null
    },
    vehicleObject: {
      type: Object,
      required: true
    },
    useAutouncle: {
      type: Boolean,
      required: false,
      default: false
    },
    vehicleTitle: {
      type: String,
      required: true
    },
    vehiclePriceLabel: {
      type: String,
      required: true
    }
  },

  methods: {
    submit() {
      if (this.useAutouncle) {
        this.$emit('rate')
      }
    }
  }
}
</script>
