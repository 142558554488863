<template>
  <div class="am-offer-configuration-financing">
    <amui-input
      v-model="selectedDownPaymentRawInput"
      :label="$t('vdp.offer.configuration.downPayment.label')"
      class="am-offer-configuration-financing__row"
      @blur="trackCalculatorChanged('calculator_down_payment')"
    />

    <am-offer-configuration-duration-select
      v-if="data.rates.length > 1"
      v-model="selectedDuration"
      :options="durationOptions"
      :label="$t('vdp.offer.configuration.termOfContract.label')"
      class="am-offer-configuration-financing__duration"
      @change="trackCalculatorChanged('calculator_duration')"
    />
    <am-offer-configuration-details
      v-else
      class="am-offer-configuration-financing__row"
      :term-of-contract="selectedDuration"
    />

    <div
      class="am-offer-configuration-financing__row am-offer-configuration-financing__small-print"
    >
      * {{ smallPrint }}
    </div>
  </div>
</template>
<script>
import AmOfferConfigurationDurationSelect from './../offer-configuration-duration-select/offer-configuration-duration-select.vue'
import AmOfferConfigurationDetails from './../offer-configuration-details/offer-configuration-details.vue'
import { unique } from './../../../../../../utils/array'
import { formatPrice } from './../../../../../../utils/formatter'
import { getCheapestFinancingOffer } from './../../../../../../utils/financing'
import {
  getNewRateByAdjustedDownPayment,
  getMaxDownPayment
} from './../../../../../../utils/down-payment'
import AmuiInput from '../../../../../../ui/components/input/input.vue'
import legalFinancingMixin from './../../mixins/legal-financing.mixin.js'

import { getGTMTracker } from '@/app/tracking/gtm.js'

export default {
  name: 'AmOfferConfigurationFinancing',

  model: {
    prop: 'offer',
    event: 'change'
  },

  components: {
    AmOfferConfigurationDurationSelect,
    AmOfferConfigurationDetails,
    AmuiInput
  },

  mixins: [legalFinancingMixin],

  props: {
    data: {
      type: Object,
      required: false,
      default: null
    },
    offer: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * the calculated rate must have at least this value.
     * value is expected in cents
     */
    minRate: {
      type: Number,
      required: false,
      default: 1000
    }
  },

  data: () => ({
    gtm: null,
    selectedDownPayment: null,
    selectedDownPaymentRawInput: null,
    selectedDuration: null
  }),

  computed: {
    durationOptions() {
      const terms = unique(this.data.rates.map(d => d.paybackPeriod))

      terms.sort((a, b) => {
        return a - b
      })

      return terms.map(term => ({
        label: term.toString(),
        value: term,
        disabled: false
      }))
    },
    calculatedMaxDownPayment() {
      return getMaxDownPayment(
        this.currentSelectedOffer.firstInstallment,
        this.currentSelectedOffer.monthlyInstallment,
        this.selectedDuration,
        this.minRate
      )
    },
    currentSelectedOffer() {
      return this.getOffer(this.selectedDuration)
    },
    calculatedRate() {
      return getNewRateByAdjustedDownPayment(
        this.selectedDownPayment,
        this.currentSelectedOffer.firstInstallment,
        this.currentSelectedOffer.monthlyInstallment,
        this.currentSelectedOffer.paybackPeriod
      )
    },
    isValidCurrentSelectedOffer() {
      return this.currentSelectedOffer !== undefined && this.calculatedRate > 0
    },
    priceSubline() {
      let subline = this.$t('vdp.offer.configuration.vat.difference')

      if (this.data.vatRate !== undefined) {
        const netRate = this.calculatedRate / (1 + this.data.vatRate / 100)

        subline = this.$t('vdp.offer.configuration.priceLabel.net', {
          value: formatPrice(netRate)
        })
      }

      return subline
    },
    price() {
      return formatPrice(this.calculatedRate)
    },
    configuredOffer() {
      const modified = {
        firstInstallment: this.selectedDownPayment,
        monthlyInstallment: this.calculatedRate // gross
      }

      return {
        vatRate: this.data.vatRate,
        rate: {
          origin: this.currentSelectedOffer,
          modified
        }
      }
    },
    smallPrint() {
      const offer = Object.assign(
        {},
        this.configuredOffer.rate.origin,
        this.configuredOffer.rate.modified
      )

      return this.getFinancingOfferLegalText(offer)
    }
  },

  created() {
    if (this.offer !== null) {
      this.setOffer(this.offer)
    } else {
      this.setCheapestOffer()
    }

    this.$watch('configuredOffer', offer => {
      this.$emit('change', offer)
    })
  },

  mounted() {
    this.gtm = getGTMTracker()
  },

  watch: {
    selectedDownPayment(value) {
      this.selectedDownPaymentRawInput = formatPrice(value).toString()
    },
    selectedDownPaymentRawInput(value) {
      // the selectedDownPaymentRawInput is necessary to keep reactivity for the input field
      let input = value.replace(/[^0-9]/g, '')

      if (input.length > 0) {
        input = parseInt(input) * 100 // to cents

        if (input < 0) {
          input = 0
        } else if (input > this.calculatedMaxDownPayment) {
          input = this.calculatedMaxDownPayment
        }
      }

      this.selectedDownPayment = input

      this.$nextTick(() => {
        this.selectedDownPaymentRawInput = formatPrice(input)
      })
    },
    selectedDuration(value) {
      if (this.isValidCurrentSelectedOffer === false) {
        this.setCheapestOffer('paybackPeriod', value)
      }
    }
  },

  methods: {
    getOffer(duration) {
      return this.data.rates.find(d => {
        return d.paybackPeriod === duration
      })
    },
    isValidOffer(duration) {
      return this.getOffer(duration) !== undefined && this.calculatedRate > 0
    },
    setCheapestOffer(filterKey, filterValue) {
      let offers = [...this.data.rates]

      if (filterKey !== undefined && filterValue !== undefined) {
        offers = offers.filter(offer => offer[filterKey] === filterValue)
      }

      const offer = getCheapestFinancingOffer(offers)

      this.setOffer(offer)
    },
    setOffer(offer) {
      this.selectedDownPayment = offer.firstInstallment
      this.selectedDuration = offer.paybackPeriod
    },
    trackCalculatorChanged(field) {
      const type = this.$t('tracking.paymentMethods.financing')

      // currently we only provide private financing offers
      const customerType = this.$t(
        'vdp.offer.configuration.financing.type.private'
      )

      const downPayment = this.selectedDownPayment / 100

      const duration = this.selectedDuration.toString()

      let payload = {
        calculator_type: type,
        calculator_customer_type: customerType,
        currency: 'EUR',
        value: Math.floor(this.calculatedRate / 100)
      }

      if (field === 'calculator_down_payment') {
        payload[field] = downPayment
      } else if (field === 'calculator_duration') {
        payload[field] = duration
      }

      this.gtm.trackEvent({
        name: 'calculator_changed',
        payload
      })
    }
  }
}
</script>
