<template>
  <div class="ce-view">
    <div v-if="getPropertyValue('consumptions')" class="ce-view__row">
      <div>
        Energieverbrauch (komb.)
      </div>
      <div>
        {{ `${getPropertyValue('consumptions.power.combined')} kWh/100km` }}
      </div>
    </div>
    <div v-if="getPropertyValue('emissions')" class="ce-view__row">
      <div>
        CO₂-Emissionen (komb.)
      </div>
      <div>
        {{ `${getPropertyValue('emissions.combined.co2')} g/km` }}
      </div>
    </div>
    <div v-if="getPropertyValue('range')" class="ce-view__row">
      <div>
        Elektrische Reichweite
      </div>
      <div>
        {{ `${getPropertyValue('range', true)} km` }}
      </div>
    </div>
    <div
      v-if="getPropertyValue('consumptions')"
      class="ce-view__row ce-view__row--mv"
    >
      <div>
        CO₂-Klasse
      </div>
      <div>
        <div>
          Auf Grundlage der CO₂-Emissionen (kombiniert)
        </div>
        <amui-card bordered inline-block>
          <amui-card-section>
            <amui-efficiency-class-bars
              :efficiency-class="
                getPropertyValue('emissions.combined.co2Class')
              "
            />
          </amui-card-section>
        </amui-card>
      </div>
    </div>
    <div v-if="getPropertyValue('consumptions.power')" class="ce-view__row">
      <div>
        Stromverbrauch
      </div>
      <div class="ce-view__inner-rows">
        <div v-if="getPropertyValue('consumptions.power.combined')">
          {{
            `${getPropertyValue(
              'consumptions.power.combined'
            )} kWh/100km (kombiniert)`
          }}
        </div>
        <div v-if="getPropertyValue('consumptions.power.city')">
          {{
            `${getPropertyValue(
              'consumptions.power.city'
            )} kWh/100km (Innenstadt)`
          }}
        </div>
        <div v-if="getPropertyValue('consumptions.power.suburban')">
          {{
            `${getPropertyValue(
              'consumptions.power.suburban'
            )} kWh/100km (Stadtrand)`
          }}
        </div>
        <div v-if="getPropertyValue('consumptions.power.rural')">
          {{
            `${getPropertyValue(
              'consumptions.power.rural'
            )} kWh/100km (Landstraße)`
          }}
        </div>
        <div v-if="getPropertyValue('consumptions.power.highway')">
          {{
            `${getPropertyValue(
              'consumptions.power.highway'
            )} kWh/100km (Autobahn)`
          }}
        </div>
      </div>
    </div>
    <div
      v-if="getPropertyValue('costModel.consumptionCosts')"
      class="ce-view__row"
    >
      <div>
        Energiekosten bei 15.000 km Jahresfahrleistung
      </div>
      <div>
        {{
          `${formatPrice(
            getPropertyValue('costModel.consumptionCosts'),
            true
          )} €/Jahr`
        }}
      </div>
    </div>
    <div v-if="getPropertyValue('costModel.tax')" class="ce-view__row">
      <div>
        Kraftfahrzeugsteuer
      </div>
      <div>
        {{
          `${formatPrice(getPropertyValue('costModel.tax', true), true)} €/Jahr`
        }}
      </div>
    </div>
    <div v-if="getPropertyValue('emissionClass')" class="ce-view__row">
      <div>
        Schadstoffklasse
      </div>
      <div>
        {{ $t('vehicle.emissionClass' + getPropertyValue('emissionClass')) }}
      </div>
    </div>
    <div v-if="getPropertyValue('emissionSticker')" class="ce-view__row">
      <div>
        Umweltplakette
      </div>
      <div>
        {{
          $t('vehicle.emissionSticker' + getPropertyValue('emissionSticker'))
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { findDeepPropertyValue } from '@/../utils/object.js'
import { AmuiCard, AmuiCardSection } from '@/../ui/components/card'
import { AmuiEfficiencyClassBars } from '@/../ui/components/efficiency-class-bars'
import { formatPrice } from '@/../utils/formatter'

export default {
  name: 'BevConsumptionAndEmissionsView',

  components: {
    AmuiCard,
    AmuiCardSection,
    AmuiEfficiencyClassBars
  },

  props: {
    rawVehicleData: {
      type: Object,
      required: true
    }
  },

  methods: {
    getPropertyValue(path) {
      return findDeepPropertyValue(this.rawVehicleData, path) ?? null
    },
    formatPrice(value, decimals = false) {
      return formatPrice(value, decimals, false)
    }
  }
}
</script>
