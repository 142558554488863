<template>
  <div class="am-vehicle-player">
    <am-vehicle-player-stage
      :items="items"
      :value="requestedSelectedItemId"
      @change="onChangeSelectedItemIdByStage"
    ></am-vehicle-player-stage>
    <am-vehicle-player-thumbnails
      v-if="items.length > 1"
      :items="items"
      :value="selectedItemId"
      @change="onChangeSelectedItemIdByThumbnails"
      class="am-vehicle-player__thumbnails"
    ></am-vehicle-player-thumbnails>
    <am-vehicle-player-navigation
      v-if="items.length > 1"
      :items="items"
      :value="selectedItemId"
      :navigate-forward-label="navigateForwardLabel"
      :navigate-backward-label="navigateBackwardLabel"
      :navigate-to-gallery-label="navigateToGalleryLabel"
      :navigate-to-rotation-label="navigateToRotationLabel"
      :navigate-to-interior-label="navigateToInteriorLabel"
      @change="onChangeSelectedItemIdByNavigation"
      class="am-vehicle-player__navigation"
    ></am-vehicle-player-navigation>
  </div>
</template>

<script>
import AmVehiclePlayerStage from './components/stage/vehicle-player-stage.vue'
import AmVehiclePlayerThumbnails from './components/thumbnails/vehicle-player-thumbnails.vue'
import AmVehiclePlayerNavigation from './components/navigation/vehicle-player-navigation.vue'

export default {
  name: 'AmVehiclePlayer',

  components: {
    AmVehiclePlayerStage,
    AmVehiclePlayerThumbnails,
    AmVehiclePlayerNavigation
  },

  props: {
    galleryItems: {
      type: Array,
      required: false,
      default: () => []
    },
    rotationClosedItems: {
      type: Array,
      required: false,
      default: () => []
    },
    rotationOpenedItems: {
      type: Array,
      required: false,
      default: () => []
    },
    interiorItems: {
      type: Array,
      required: false,
      default: () => []
    },
    navigateForwardLabel: {
      type: String,
      required: true
    },
    navigateBackwardLabel: {
      type: String,
      required: true
    },
    navigateToGalleryLabel: {
      type: String,
      required: true
    },
    navigateToRotationLabel: {
      type: String,
      required: true
    },
    navigateToInteriorLabel: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      selectedItemId: null,
      requestedSelectedItemId: null
    }
  },

  watch: {
    items() {
      this.setSelectedItem()
    }
  },

  computed: {
    items() {
      let id = 0

      const getId = id => {
        return id.toString()
      }

      let items = this.galleryItems.map(item => {
        return {
          id: getId(id++),
          type: 'gallery',
          data: item
        }
      })

      if (this.rotationClosedItems.length || this.rotationOpenedItems.length) {
        items.push({
          id: getId(++id),
          type: 'rotation'
        })
      }

      if (this.interiorItems.length) {
        items.push({
          id: getId(++id),
          type: 'interior'
        })
      }

      return items
    }
  },

  created() {
    this.setSelectedItem()
  },

  methods: {
    setSelectedItem() {
      if (this.items.length) {
        this.selectedItemId = this.items[0].id
        this.requestedSelectedItemId = this.items[0].id
      }
    },
    onChangeSelectedItemIdByStage(id) {
      this.selectedItemId = id
      this.requestedSelectedItemId = id
    },
    onChangeSelectedItemIdByThumbnails(id) {
      this.requestedSelectedItemId = id
    },
    onChangeSelectedItemIdByNavigation(id) {
      this.requestedSelectedItemId = id
    }
  }
}
</script>

<style></style>
