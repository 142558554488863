<template>
  <div class="am-offer-configuration-details">
    <div v-if="downPayment" class="am-offer-configuration-details__item">
      <div>{{ $t('vdp.offer.configuration.downPayment.shortLabel') }}</div>
      <div>
        {{
          $t('vdp.offer.configuration.downPayment.value', {
            value: formatPrice(downPayment)
          })
        }}
      </div>
    </div>
    <div v-if="annualMileage" class="am-offer-configuration-details__item">
      <div>{{ $t('vdp.offer.configuration.annualMileage.label') }}</div>
      <div>
        {{
          $t('vdp.offer.configuration.annualMileage.value', {
            value: formatMileage(annualMileage)
          })
        }}
      </div>
    </div>
    <div v-if="termOfContract" class="am-offer-configuration-details__item">
      <div>
        {{ $t('vdp.offer.configuration.termOfContract.shortLabel') }}
      </div>
      <div>
        {{
          $tc('vdp.offer.configuration.termOfContract.value', termOfContract, {
            value: termOfContract
          })
        }}
      </div>
    </div>
  </div>
</template>
<script>
import { formatPrice, formatMileage } from './../../../../../../utils/formatter'

export default {
  name: 'AmOfferConfigurationDetails',

  props: {
    downPayment: {
      type: Number,
      required: false,
      default: null
    },
    annualMileage: {
      type: Number,
      required: false,
      default: null
    },
    termOfContract: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    formatPrice: () => formatPrice,
    formatMileage: () => formatMileage
  }
}
</script>
