<template>
  <div
    class="amui-content-collapsable"
    :class="{ 'amui-content-collapsable--collapsed': isCollapsed }"
  >
    <div
      class="amui-content-collapsable__content-wrapper"
      ref="content-wrapper"
    >
      <div ref="content"><slot /></div>
    </div>
    <div
      v-if="useCollapsable && (isCollapsed || labelExpanded !== null)"
      class="amui-content-collapsable__button-wrapper"
    >
      <button @click="toggle">
        <span>{{ isCollapsed ? labelCollapsed : labelExpanded }}</span>
        <amui-icon
          name="chevron-down"
          size="sm"
          viewport="s"
          class="amui-content-collapsable__icon"
        />
      </button>
    </div>
  </div>
</template>
<script>
// anzeigen oder nicht berechnen
import { AmuiIcon } from '../icon'

export default {
  name: 'AmuiContentCollapsable',

  components: {
    AmuiIcon
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    labelExpanded: {
      type: String,
      required: false,
      default: null
    },
    labelCollapsed: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      isCollapsed: this.value,
      resizeObserverInstance: null,
      cachedContentHeight: 0,
      useCollapsable: false
    }
  },

  watch: {
    value(value) {
      this.isCollapsed = value
    }
  },

  mounted() {
    this.initResizeObserver()
  },

  beforeDestroy() {
    this.resizeObserverInstance && this.resizeObserverInstance.disconnect()
  },

  methods: {
    initResizeObserver() {
      this.resizeObserverInstance = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            const contentHeight = this.$refs.content.clientHeight
            if (contentHeight !== this.cachedContentHeight) {
              const contentWrapperRef = this.$refs['content-wrapper']
              const definedMaxContentHeight = parseInt(
                getComputedStyle(contentWrapperRef)
                  .getPropertyValue('--height')
                  .trim()
              )

              if (definedMaxContentHeight) {
                this.useCollapsable = contentHeight > definedMaxContentHeight
              }

              this.cachedContentHeight = contentHeight
            }
          }
        }
      })
      this.resizeObserverInstance.observe(this.$refs.content)
    },
    toggle() {
      this.isCollapsed = !this.isCollapsed
      this.$emit('change', this.isCollapsed)
    }
  }
}
</script>
