<template>
  <form class="am-checkout-selection" autocomplete="on">
    <div>
      <amui-chip
        :label="stepNumber + '/' + totalStepsNumber"
        type="secondary"
      />
    </div>
    <amui-headline
      :text="$t('checkout.selection.headline')"
      html-tag="div"
      looks-like="h5"
    />
    <div class="am-checkout-selection__radios">
      <amui-radio
        v-for="option in checkoutTypeOptions"
        :key="option.value"
        v-model="formData.checkoutType"
        :val="option.value"
        :label="option.label"
        :invalid="invalid"
        @change="onChangeCheckoutType"
        viewport="s"
      />
    </div>
  </form>
</template>

<script>
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiRadio } from '@/../ui/components/radio'
import { AmuiChip } from '@/../ui/components/chip'

export default {
  name: 'AmCheckoutPersonalData',

  components: {
    AmuiHeadline,
    AmuiRadio,
    AmuiChip
  },

  props: {
    stepNumber: {
      type: Number,
      required: true
    },
    totalStepsNumber: {
      type: Number,
      required: true
    },
    checkoutType: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      formData: {
        checkoutType: this.checkoutType
      },
      checkoutTypeOptions: [
        {
          label: this.$t('checkout.selection.options.offer'),
          value: 'offer'
        },
        {
          label: this.$t('checkout.selection.options.testdrive'),
          value: 'testdrive'
        }
      ],
      invalid: false
    }
  },

  methods: {
    submit() {
      const found = this.checkoutTypeOptions.find(
        o => o.value === this.formData.checkoutType
      )
      this.invalid = false

      if (found !== undefined) {
        this.$emit('save', found.value)
      } else {
        this.$emit('error')
        this.invalid = true
      }
    },
    onChangeCheckoutType(checkoutType) {
      this.$emit('update:checkoutType', checkoutType)
      this.invalid = false
    }
  }
}
</script>
