export const findDeepPropertyValue = (obj, path) => {
  const parts = path.split('.')

  if (obj[parts[0]] !== undefined) {
    if (parts.length === 1) {
      return obj[parts[0]]
    }

    return findDeepPropertyValue(obj[parts[0]], parts.slice(1).join('.'))
  }

  return undefined
}

export default {
  findDeepPropertyValue
}
