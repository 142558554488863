<template>
  <div class="amui-radio" :class="classes">
    <div class="amui-radio__wrapper" @click="() => handleChange(val)">
      <div class="amui-radio__inner">
        <div class="amui-radio__icon"></div>
        <input
          type="radio"
          :name="value"
          :value="val"
          class="amui-radio__input"
        />
      </div>
      <div class="amui-radio__label">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmuiRadio',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    val: {
      type: [String, Number, Array],
      required: true
    },
    value: {
      type: [String, Number, Array],
      required: true
    },
    label: {
      type: String,
      require: true
    },
    checked: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checkableWhenDisabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    viewport: {
      type: String,
      default: null,
      validate(value) {
        return ['s'].includes(value)
      }
    }
  },

  computed: {
    classes() {
      return {
        'amui-radio--active': this.val === this.value || this.checked,
        'amui-radio--responsive': this.viewport === null,
        'amui-radio--disabled': this.disabled,
        'amui-radio--invalid': this.invalid
      }
    }
  },

  methods: {
    handleChange(val) {
      if (this.disabled && !this.checkableWhenDisabled) return

      this.$emit('change', val)
    }
  }
}
</script>
