<template>
  <div class="ce-view">
    <div v-if="getPropertyValue('consumptions')" class="ce-view__row">
      <div>
        Energieverbrauch (komb.)
      </div>
      <div>
        {{
          `${getPropertyValue('consumptions.fuel.combined')} ${consumptionUnit}`
        }}
      </div>
    </div>
    <div v-if="getPropertyValue('emissions')" class="ce-view__row">
      <div>
        CO₂-Emissionen (komb.)
      </div>
      <div>
        {{ `${getPropertyValue('emissions.combined.co2')} g/km` }}
      </div>
    </div>
    <div
      v-if="getPropertyValue('consumptions')"
      class="ce-view__row ce-view__row--mv"
    >
      <div>
        CO₂-Klasse
      </div>
      <div>
        <div>
          Auf Grundlage der CO₂-Emissionen (kombiniert)
        </div>
        <amui-card bordered inline-block>
          <amui-card-section>
            <amui-efficiency-class-bars
              :efficiency-class="
                getPropertyValue('emissions.combined.co2Class')
              "
            />
          </amui-card-section>
        </amui-card>
      </div>
    </div>
    <div v-if="getPropertyValue('consumptions.fuel')" class="ce-view__row">
      <div>
        Kraftstoffverbrauch
      </div>
      <div class="ce-view__inner-rows">
        <div v-if="getPropertyValue('consumptions.fuel.combined')">
          {{
            `${getPropertyValue(
              'consumptions.fuel.combined'
            )} ${consumptionUnit} (kombiniert)`
          }}
        </div>
        <div v-if="getPropertyValue('consumptions.fuel.city')">
          {{
            `${getPropertyValue(
              'consumptions.fuel.city'
            )} ${consumptionUnit} (Innenstadt)`
          }}
        </div>
        <div v-if="getPropertyValue('consumptions.fuel.suburban')">
          {{
            `${getPropertyValue(
              'consumptions.fuel.suburban'
            )} ${consumptionUnit} (Stadtrand)`
          }}
        </div>
        <div v-if="getPropertyValue('consumptions.fuel.rural')">
          {{
            `${getPropertyValue(
              'consumptions.fuel.rural'
            )} ${consumptionUnit} (Landstraße)`
          }}
        </div>
        <div v-if="getPropertyValue('consumptions.fuel.highway')">
          {{
            `${getPropertyValue(
              'consumptions.fuel.highway'
            )} ${consumptionUnit} (Autobahn)`
          }}
        </div>
      </div>
    </div>
    <div
      v-if="getPropertyValue('costModel.consumptionCosts')"
      class="ce-view__row"
    >
      <div>
        Energiekosten bei 15.000 km Jahresfahrleistung
      </div>
      <div>
        {{
          `${formatPrice(
            getPropertyValue('costModel.consumptionCosts'),
            true
          )} €/Jahr`
        }}
      </div>
    </div>
    <div v-if="getPropertyValue('costModel.tax')" class="ce-view__row">
      <div>
        Kraftfahrzeugsteuer
      </div>
      <div>
        {{
          `${formatPrice(getPropertyValue('costModel.tax', true), true)} €/Jahr`
        }}
      </div>
    </div>
    <div v-if="getPropertyValue('emissionClass')" class="ce-view__row">
      <div>
        Schadstoffklasse
      </div>
      <div>
        {{ $t('vehicle.emissionClass' + getPropertyValue('emissionClass')) }}
      </div>
    </div>
    <div v-if="getPropertyValue('emissionSticker')" class="ce-view__row">
      <div>
        Umweltplakette
      </div>
      <div>
        {{
          $t('vehicle.emissionSticker' + getPropertyValue('emissionSticker'))
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { findDeepPropertyValue } from '@/../utils/object.js'
import { AmuiCard, AmuiCardSection } from '@/../ui/components/card'
import { AmuiEfficiencyClassBars } from '@/../ui/components/efficiency-class-bars'
import { formatPrice } from '@/../utils/formatter'

// will be also used for fuel=HYDROGENIUM as it seems to be same except for the units
export default {
  name: 'IceConsumptionAndEmissionsView',

  components: {
    AmuiCard,
    AmuiCardSection,
    AmuiEfficiencyClassBars
  },

  props: {
    rawVehicleData: {
      type: Object,
      required: true
    }
  },

  computed: {
    consumptionUnit() {
      const { fuel } = this.rawVehicleData

      if (['HYDROGENIUM', 'CNG'].includes(fuel)) {
        return 'kg/100km'
      }

      return 'l/100km'
    }
  },

  methods: {
    getPropertyValue(path) {
      return findDeepPropertyValue(this.rawVehicleData, path) ?? null
    },
    formatPrice(value, decimals = false) {
      return formatPrice(value, decimals, false)
    }
  }
}
</script>
