var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'amui-button-toggle': true,
    'amui-button-toggle--animated': _vm.useAnimations
  }},[(_vm.options.length)?_c('div',{staticClass:"amui-button-toggle__active",style:({
      width: _vm.activeElementProperties.width + 'px',
      transform: 'translateX(' + _vm.activeElementProperties.offsetLeft + 'px)'
    })}):_vm._e(),_vm._l((_vm.options),function(option,index){return _c('label',{key:'label' + index + option.value,ref:"button",refInFor:true,class:{
      'amui-button-toggle__button': true,
      'amui-button-toggle__button--active': option.value === _vm.selectedValue
    },attrs:{"aria-label":option.label}},[_c('input',{key:'input' + index + option.value,attrs:{"type":"radio","name":_vm.name},domProps:{"value":option.value,"checked":option.value === _vm.selectedValue},on:{"change":function () { return _vm.change(option.value); }}}),_c('div',[(option.icon !== undefined)?_c('amui-icon',{staticClass:"amui-button-toggle__icon",attrs:{"name":option.icon,"size":"lg","viewport":"s"}}):_c('span',{staticClass:"amui-button-toggle__button-label"},[_vm._v(_vm._s(option.label))])],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }