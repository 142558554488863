import { render, staticRenderFns } from "./bev.vue?vue&type=template&id=128b70df&"
import script from "./bev.vue?vue&type=script&lang=js&"
export * from "./bev.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports