<template>
  <div class="amui-card-section" :class="{ 'amui-card-section--small': small }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AmuiCardSection',

  props: {
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
