export const historyPropertyList = [
  'accidentDamaged',
  'condition',
  'damageByHail',
  'damageUnrepaired',
  'firstRegistration',
  'fullServiceHistory',
  'generalInspection',
  'mileage',
  'newHuAu',
  'newService',
  'nonSmokerVehicle',
  'numberOfPreviousOwners',
  'roadworthy',
  'taxi'
]

export default {
  historyPropertyList
}
