export const categorizedFeaturesPropertyList = {
  suspension: ['abs', 'airSuspension', 'performanceHandlingSystem'],
  connectivity: [
    'androidAuto',
    'bluetooth',
    'carplay',
    'navigationPreparation',
    'navigationSystem',
    'onBoardComputer',
    'touchscreen',
    'usb',
    'wifiHotspot',
    'wirelessCharging'
  ],
  climateControl: [
    {
      key: 'climatisation',
      value: 'NO_CLIMATISATION'
    },
    {
      key: 'climatisation',
      value: 'MANUAL_CLIMATISATION'
    },
    {
      key: 'climatisation',
      value: 'AUTOMATIC_CLIMATISATION'
    },
    {
      key: 'climatisation',
      value: 'AUTOMATIC_CLIMATISATION_2_ZONES'
    },
    {
      key: 'climatisation',
      value: 'AUTOMATIC_CLIMATISATION_3_ZONES'
    },
    {
      key: 'climatisation',
      value: 'AUTOMATIC_CLIMATISATION_4_ZONES'
    }
  ],
  driverAssistance: [
    'blindSpotMonitor',
    'collisionAvoidance',
    'distanceWarningSystem',
    'fatigueWarningSystem',
    'laneDepartureWarning',
    'massageSeats',
    'nightVisionAssist',
    {
      key: 'parkingAssistants',
      value: 'FRONT_SENSORS'
    },
    {
      key: 'parkingAssistants',
      value: 'REAR_SENSORS'
    },
    {
      key: 'parkingAssistants',
      value: 'REAR_VIEW_CAM'
    },
    {
      key: 'parkingAssistants',
      value: 'AUTOMATIC_PARKING'
    },
    {
      key: 'parkingAssistants',
      value: 'CAM_360_DEGREES'
    },
    'rearTrafficAlert',
    'speedLimiter',
    'tractionControlSystem',
    'trafficSignRecognition'
  ],
  comfort: [
    'armRest',
    'auxiliaryHeating',
    'box',
    'cargoBarrier',
    'centralLocking',
    'disabledAccessible',
    'disabledConversion',
    'electricAdjustableSeats',
    'electricExteriorMirrors',
    'electricHeatedRearSeats',
    'electricHeatedSeats',
    'electricStarter',
    'electricTailgate',
    'electricWindows',
    'foldFlatPassengerSeat',
    'handsFreePhoneSystem',
    'headUpDisplay',
    'heatedSteeringWheel',
    'keylessEntry',
    'kickstarter',
    'leatherSteeringWheel',
    'lumbarSupport',
    'memorySeats',
    'multifunctionalWheel',
    'paddleShifters',
    'powerAssistedSteering',
    'roofRails',
    'skiBag',
    'smokersPackage',
    'tintedWindows',
    'ventilatedSeats',
    'voiceControl'
  ],
  drivetrain: ['rangeExtender', 'startStopSystem'],
  entertainment: [
    'cdMultichanger',
    'cdPlayer',
    'integratedMusicStreaming',
    {
      key: 'radio',
      value: 'TUNER'
    },
    {
      key: 'radio',
      value: 'DAB_RADIO'
    },
    'soundSystem',
    'tv'
  ],
  exteriorTrim: [
    {
      key: 'battery',
      value: 'BATTERY_RENTED'
    },
    {
      key: 'battery',
      value: 'BATTERY_PURCHASED'
    },
    {
      key: 'battery',
      value: 'BATTERY_MISSING'
    },
    {
      key: 'bendingLightsType',
      value: 'BENDING_LIGHTS'
    },
    {
      key: 'bendingLightsType',
      value: 'ADAPTIVE_BENDING_LIGHTS'
    },
    {
      key: 'daytimeRunningLamps',
      value: 'DAYTIME_RUNNING_LIGHTS'
    },
    {
      key: 'daytimeRunningLamps',
      value: 'LED_RUNNING_LIGHTS'
    },
    'foldingExteriorMirrors',
    'foldingRoof',
    {
      key: 'headlightType',
      value: 'XENON_HEADLIGHTS'
    },
    {
      key: 'headlightType',
      value: 'BI_XENON_HEADLIGHTS'
    },
    {
      key: 'headlightType',
      value: 'LED_HEADLIGHTS'
    },
    {
      key: 'headlightType',
      value: 'LASER_HEADLIGHTS'
    },
    'matteColor',
    'metallic',
    'panoramicGlassRoof',
    'sportPackage',
    'sunroof',
    'trailerAssist',
    'virtualSideMirror',
    'windshield'
  ],
  interiorTrim: [
    'ambientLighting',
    'digitalCockpit',
    'dimmingInteriorMirror',
    {
      key: 'interiorType',
      value: 'LEATHER'
    },
    {
      key: 'interiorType',
      value: 'PARTIAL_LEATHER'
    },
    {
      key: 'interiorType',
      value: 'FABRIC'
    },
    {
      key: 'interiorType',
      value: 'VELOUR'
    },
    {
      key: 'interiorType',
      value: 'ALCANTARA'
    },
    {
      key: 'interiorType',
      value: 'IMITATION_LEATHER'
    },
    {
      key: 'interiorType',
      value: 'OTHER_INTERIOR_TYPE'
    },
    'sportSeats'
  ],
  internal: [],
  safety: [
    {
      key: 'airbag',
      value: 'DRIVER_AIRBAG'
    },
    {
      key: 'airbag',
      value: 'FRONT_AIRBAGS'
    },
    {
      key: 'airbag',
      value: 'FRONT_AND_SIDE_AIRBAGS'
    },
    {
      key: 'airbag',
      value: 'FRONT_AND_SIDE_AND_MORE_AIRBAGS'
    },
    'alarmSystem',
    'automaticRainSensor',
    'breakdownService',
    'breakdownService',
    'breakdownService',
    'electricBackseatAdjustment',
    'emergencyCallSystem',
    'esp',
    'frontFogLights',
    'glareFreeHighBeam',
    'headlightWasherSystem',
    'heatedWindshield',
    'highBeamAssist',
    'hillStartAssist',
    'immobilizer',
    'isofix',
    'lightSensor',
    'passengerSeatIsofixPoint',
    'rollOverBar',
    'tirePressureMonitoring',
    'winterPackage'
  ],
  seats: [],
  wheels: [
    'alloyWheels',
    'allSeasonTires',
    'steelWheels',
    'summerTires',
    'winterTires'
  ]
}

export default {
  categorizedFeaturesPropertyList
}
