<template>
  <div
    class="amui-financing-popover"
    :class="{
      'amui-financing-popover--show': showOverlay
    }"
    v-click-outside="hideOverlay"
  >
    <amui-icon
      :name="showOverlay ? 'close-circle' : 'info'"
      ref="trigger"
      aria-describedby="tooltip"
      class="amui-financing-popover-trigger"
      @click.native.stop.prevent="onToggle"
    />
    <div
      class="amui-financing-popover-overlay"
      ref="overlay"
      role="tooltip"
      v-show="showOverlay"
    >
      <amui-financing-popover-content-financing
        v-if="isFinancing"
        :offer="offer"
      />
      <amui-financing-popover-content-leasing
        v-else-if="isLeasing"
        :offer="offer"
      />
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import AmuiFinancingPopoverContentFinancing from './components/financing-popover-content-financing.vue'
import AmuiFinancingPopoverContentLeasing from './components/financing-popover-content-leasing.vue'
import ClickOutside from 'vue-click-outside'
import { AmuiIcon } from './../../../ui/components/icon'

export default {
  name: 'AmuiFinancingPopover',

  components: {
    AmuiFinancingPopoverContentFinancing,
    AmuiFinancingPopoverContentLeasing,
    AmuiIcon
  },

  directives: {
    ClickOutside
  },

  props: {
    offer: {
      type: Object,
      required: true
    },

    // fixed or absolute
    popoverStrategy: {
      type: String,
      required: false,
      default: 'absolute'
    }
  },

  data() {
    return {
      popperInstance: null,
      showOverlay: false
    }
  },

  computed: {
    isLeasing() {
      return this.offer.type === 'leasing'
    },
    isFinancing() {
      return this.offer.type === 'financing'
    }
  },

  mounted() {
    this.popperInstance = createPopper(
      this.$refs.trigger.$el,
      this.$refs.overlay,
      {
        strategy: this.popoverStrategy,
        placement: 'top'
      }
    )
  },

  methods: {
    onToggle() {
      this.showOverlay = !this.showOverlay
      this.popperInstance.update()
    },
    hideOverlay() {
      this.showOverlay = false
    }
  }
}
</script>
