import {
  getTitle as getVehicleTitle,
  getDefaultImage as getVehicleDefaultImage
} from '@/../utils/vehicle'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('core', ['rawVehicleMarketCompanyData']),
    vehiclePlayerGalleryItems() {
      let images = []

      if (this.rawVehicleData) {
        if (
          this.rawVehicleData.media &&
          Array.isArray(this.rawVehicleData.media.gallery)
        ) {
          // cpo image sources
          images = this.rawVehicleData.media.gallery.map(image => ({
            origin: 'cpo',
            type: 'image',
            source: image.original2,
            alt: getVehicleTitle(this.rawVehicleData)
          }))
        } else if (
          this.rawVehicleData.images &&
          Array.isArray(this.rawVehicleData.images)
        ) {
          // mobile image sources
          images = this.rawVehicleData.images.map(image => {
            const source = image.ref.replace(
              'img.classistatic.de',
              'pictures.automedia.de'
            )

            return {
              origin: 'mobile',
              type: 'image',
              source,
              alt: null
            }
          })
        }

        if (images.length === 0) {
          images.push({
            origin: 'cpo',
            type: 'image',
            source: getVehicleDefaultImage(
              this.rawVehicleMarketCompanyData.vehicleDefaultImages,
              this.rawVehicleData
            ),
            alt: this.$t(
              'vehicleClass.' +
                this.rawVehicleData.vehicleClass +
                '.defaultImageAltText'
            )
          })
        }
      }

      return images
    },
    vehiclePlayerRotationClosedItems() {
      let images = []

      if (
        this.rawVehicleData &&
        this.rawVehicleData.media &&
        Array.isArray(this.rawVehicleData.media.exterior)
      ) {
        images = this.rawVehicleData.media.exterior
          .filter(image => image.type === 'close')
          .map(image => ({
            source: image.basepath
          }))
      }

      return images
    },
    vehiclePlayerRotationOpenedItems() {
      let images = []

      if (
        this.rawVehicleData &&
        this.rawVehicleData.media &&
        Array.isArray(this.rawVehicleData.media.exterior)
      ) {
        images = this.rawVehicleData.media.exterior
          .filter(image => image.type === 'open')
          .map(image => ({
            source: image.basepath
          }))
      }

      return images
    },
    vehiclePlayerInteriorItems() {
      let images = []

      if (
        this.rawVehicleData &&
        this.rawVehicleData.media &&
        Array.isArray(this.rawVehicleData.media.interior)
      ) {
        images = this.rawVehicleData.media.interior.map(image => ({
          source: image.basePath
        }))
      }

      return images
    }
  }
}
