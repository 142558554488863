<template>
  <amui-financing-popover-content-layout
    :header="header"
    :body="body"
    :foot-notes="footNotes"
  />
</template>

<script>
import AmuiFinancingPopoverContentLayout from './financing-popover-content-layout.vue'
import { formatPrice } from './../../../../utils/formatter'

export default {
  name: 'AmuiFinancingPopoverContentFinancing',

  components: {
    AmuiFinancingPopoverContentLayout
  },

  props: {
    offer: {
      type: Object,
      required: true
    }
  },

  computed: {
    header() {
      return {
        label: 'Mtl. Finanzierungsrate **',
        value: formatPrice(this.offer.data.monthlyInstallment) + ' €'
      }
    },
    // copied over and modified from price calculator
    formattedOfferDetails() {
      const offer = this.offer.data

      let additionalCosts = null

      if (
        offer.destinationCharges === undefined &&
        offer.registrationFees === undefined
      ) {
        additionalCosts = this.$t(
          'ui.priceCalculator.legal.additionalCosts.noInfoDestinationChargesAndRegistrationFees'
        )
      } else if (
        offer.destinationCharges === 0 &&
        offer.registrationFees === 0
      ) {
        additionalCosts = this.$t(
          'ui.priceCalculator.legal.additionalCosts.inclusiveDestinationChargesAndRegistrationFees'
        )
      } else if (offer.destinationCharges > 0 && offer.registrationFees > 0) {
        additionalCosts = this.$t(
          'ui.priceCalculator.legal.additionalCosts.exclusiveDestinationChargesAndRegistrationFees',
          {
            sum: formatPrice(
              offer.destinationCharges + offer.registrationFees,
              true
            )
          }
        )
      } else {
        if (offer.destinationCharges === 0) {
          additionalCosts = this.$t(
            'ui.priceCalculator.legal.additionalCosts.inclusiveDestinationCharges'
          )
        } else if (offer.destinationCharges > 0) {
          additionalCosts = this.$t(
            'ui.priceCalculator.legal.additionalCosts.exclusiveDestinationCharges',
            {
              sum: formatPrice(offer.destinationCharges, true)
            }
          )
        } else {
          // undefined
          additionalCosts = this.$t(
            'ui.priceCalculator.legal.additionalCosts.noInfoDestinationCharges'
          )
        }

        if (offer.registrationFees === 0) {
          additionalCosts += this.$t(
            'ui.priceCalculator.legal.additionalCosts.inclusiveRegistrationFees'
          )
        } else if (offer.registrationFees > 0) {
          additionalCosts += this.$t(
            'ui.priceCalculator.legal.additionalCosts.exclusiveRegistrationFees',
            {
              sum: formatPrice(offer.registrationFees, true)
            }
          )
        } else {
          // undefined
          additionalCosts += this.$t(
            'ui.priceCalculator.legal.additionalCosts.noInfoRegistrationFees'
          )
        }
      }

      let closingCosts = null

      if (offer.closingCosts !== undefined) {
        closingCosts = formatPrice(offer.closingCosts, true)
      }

      let paymentProtectionInsurance = null

      if (offer.paymentProtectionInsurance !== undefined) {
        paymentProtectionInsurance =
          formatPrice(offer.paymentProtectionInsurance, true) + ' €'
      }

      let additionalConditions = null

      if (offer.conditions !== undefined) {
        additionalConditions = this.$t(
          'ui.priceCalculator.financing.legal.additionalConditions',
          {
            text: formatPrice(offer.conditions, true)
          }
        )
      }

      let typeOfNominalInterestRateText

      if (offer.typeOfNominalInterestRate === 'BOUND') {
        typeOfNominalInterestRateText =
          'gebunden über die gesamte Vertragslaufzeit'
      } else if (offer.typeOfNominalInterestRate === 'VARIABLE') {
        typeOfNominalInterestRateText = ''
      } else if (offer.typeOfNominalInterestRate === 'COMBINED') {
        typeOfNominalInterestRateText = ''
      }

      return {
        bank: offer.bank,
        annualPercentageRate: offer.annualPercentageRate + ' %',
        paybackPeriod: offer.paybackPeriod + ' Monate',
        firstInstallment: formatPrice(offer.firstInstallment, true) + ' €',
        finalInstallment: formatPrice(offer.finalInstallment, true) + ' €',
        nominalInterestRate: offer.nominalInterestRate + ' % p.a.',
        grossLoanAmount: formatPrice(offer.grossLoanAmount, true) + ' €',
        netLoanAmount: formatPrice(offer.netLoanAmount, true) + ' €',
        closingCosts: closingCosts,
        paymentProtectionInsurance: paymentProtectionInsurance,
        additionalCosts: additionalCosts,
        conditions: additionalConditions,
        typeOfNominalInterestRateText: typeOfNominalInterestRateText
      }
    },
    body() {
      const retVal = [
        {
          label: 'Effektiver Jahreszins',
          value: this.formattedOfferDetails.annualPercentageRate
        },
        {
          label: 'Vertragslaufzeit',
          value: this.formattedOfferDetails.paybackPeriod
        },
        {
          label: 'Anzahlung',
          value: this.formattedOfferDetails.firstInstallment
        },
        {
          label: 'Schlussrate',
          value: this.formattedOfferDetails.finalInstallment
        },
        {
          label: 'Sollzins *',
          value: this.formattedOfferDetails.nominalInterestRate
        }
      ]

      if (this.formattedOfferDetails.paymentProtectionInsurance !== null) {
        retVal.push({
          label: 'Ratenabsicherung',
          value: this.formattedOfferDetails.paymentProtectionInsurance
        })
      }

      if (this.formattedOfferDetails.closingCosts !== null) {
        retVal.push({
          label: 'Abschlussgebühren',
          value: this.formattedOfferDetails.closingCosts
        })
      }

      retVal.push({
        label: 'Bruttodarlehensbetrag',
        value: this.formattedOfferDetails.grossLoanAmount
      })

      retVal.push({
        label: 'Nettodarlehensbetrag',
        value: this.formattedOfferDetails.netLoanAmount
      })

      if (this.formattedOfferDetails.additionalCosts !== null) {
        retVal.push({
          label: this.formattedOfferDetails.additionalCosts,
          value: null
        })
      }

      if (this.formattedOfferDetails.additionalConditions !== null) {
        retVal.push({
          label: this.formattedOfferDetails.additionalConditions,
          value: null
        })
      }

      return retVal
    },
    footNotes() {
      return [
        '*' + this.formattedOfferDetails.typeOfNominalInterestRateText,
        '**' + this.formattedOfferDetails.bank
      ]
    }
  }
}
</script>
