export const getImageQualityParams = () => {
  let pixelRatio = window.devicePixelRatio || 1

  // window.navigator.connection
  // NetworkInformation {onchange: null, effectiveType: '4g', rtt: 50, downlink: 10, saveData: false}

  const connection = window.navigator?.connection

  if (connection) {
    const ect = connection.effectiveType

    // use pixel ratio 1 for slow connections
    if (ect && ['slow-2g', '2g', '3g'].includes(ect)) {
      pixelRatio = 1
    }
  }

  return { pixelRatio }
}

export default {
  getImageQualityParams
}
