<template>
  <div class="am-vnf">
    <div class="am-vnf__inner">
      <amui-compact-toolbar
        v-if="appMode === 'widget'"
        class="am-vnf__compact-toolbar"
      >
        <amui-compact-link
          v-if="favoriteVehiclesCount"
          :label="$t('vdp.toolbar.favorites')"
          icon="favorite"
          :badge="favoriteVehiclesCount"
          @click.native="onClickFavorites"
        />
        <amui-compact-link
          :label="$t('vdp.toolbar.search')"
          icon="tune-simple"
          @click.native="onClickSearch"
        />
      </amui-compact-toolbar>

      <amui-headline html-tag="h1" looks-like="h5" class="am-vnf__headline">{{
        $t('vdp.not-found.headline')
      }}</amui-headline>
      <div class="am-vnf__text">
        {{ $t(isAdMode ? 'vdp.not-found.textAdMode' : 'vdp.not-found.text') }}
      </div>
      <amui-button
        v-if="!isAdMode"
        icon="tune-simple"
        :label="$t('vdp.not-found.cta')"
        @click="onClickSearch"
      />
    </div>
  </div>
</template>

<script>
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiButton } from '@/../ui/components/button'
import { AmuiCompactToolbar } from '@/../ui/components/compact-toolbar'
import { AmuiCompactLink } from '@/../ui/components/compact-link'
import { mapState } from 'vuex'

export default {
  name: 'AmVehicleNotFound',

  components: {
    AmuiHeadline,
    AmuiButton,
    AmuiCompactToolbar,
    AmuiCompactLink
  },

  metaInfo() {
    return {
      title: this.$t('vdp.not-found.meta.title'),
      meta: [
        {
          name: 'description',
          content: this.$t('vdp.not-found.meta.description')
        },
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    }
  },

  props: {
    isAdMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    ...mapState('core', ['appMode']),
    ...mapState('favorites', {
      favoriteVehiclesCount: state => state.vehicles.length
    })
  },

  methods: {
    onClickFavorites() {
      this.$router.push({ name: 'favorites' })
    },
    onClickSearch() {
      this.$router.push({ name: 'app' })
    }
  }
}
</script>
