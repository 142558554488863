<template>
  <div class="am-configuration-price-display">
    <amui-headline
      html-tag="div"
      looks-like="h6"
      class="am-configuration-price-display__price"
      >{{
        $t(grossPriceI18nTemplate, {
          price: formatPrice(grossPrice)
        })
      }}<sup v-if="grossPriceReferenceSymbol">{{
        grossPriceReferenceSymbol
      }}</sup></amui-headline
    >
    <div class="am-configuration-price-display__label-bottom">
      <template v-if="vatRate === null && type === 'purchase'">
        <span>{{ $t('vat.difference') }}</span>
      </template>
      <template v-else-if="vatRate !== null">
        {{
          $t('price.indications.simple', {
            price: formatPrice(netPrice)
          })
        }}
        <span>{{ $t('price.indications.suffix.net') }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import { AmuiHeadline } from '@/../ui/components/headline'
import { formatPrice as _formatPrice } from './../../../../../../utils/formatter'

export default {
  name: 'AmConfigurationPriceDisplay',

  components: {
    AmuiHeadline
  },

  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['purchase', 'leasing', 'financing'].includes(value)
      }
    },
    grossPrice: {
      type: Number,
      required: true
    },
    grossPriceReferenceSymbol: {
      type: String,
      required: false,
      default: null
    },
    grossPriceI18nTemplate: {
      type: String,
      required: false,
      default: 'price.indications.simple'
    },
    netPrice: {
      type: Number,
      required: false,
      default: null
    },
    vatRate: {
      type: Number,
      required: false,
      default: null
    }
  },

  methods: {
    formatPrice(value) {
      return _formatPrice(value)
    }
  }
}
</script>
