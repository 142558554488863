export const technicalPropertyList = [
  'amCurbWeigth',
  'amLadenWeight',
  'amPayload',
  'amWheelbase',
  'biodieselConversion',
  'biodieselSuitable',
  'catalyticConverter',
  'category',
  'cubicCapacity',
  'dimension.height',
  'dimension.length',
  'dimension.width',
  'doors',
  'driveType',
  'drivingModes',
  'dynamicChassisControl',
  'e10Enabled',
  'fourWheelDrive',
  'gearbox',
  'hybridPlugin',
  'particulateFilterDiesel',
  'power',
  'rightHandDrive',
  'seats',
  'vegetableoilfuelConversion',
  'vegetableoilfuelSuitable'
]

export default {
  technicalPropertyList
}
