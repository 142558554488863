<template>
  <div
    :class="{
      'amui-button-toggle': true,
      'amui-button-toggle--animated': useAnimations
    }"
  >
    <div
      v-if="options.length"
      class="amui-button-toggle__active"
      :style="{
        width: activeElementProperties.width + 'px',
        transform: 'translateX(' + activeElementProperties.offsetLeft + 'px)'
      }"
    ></div>
    <label
      v-for="(option, index) in options"
      :key="'label' + index + option.value"
      :aria-label="option.label"
      ref="button"
      :class="{
        'amui-button-toggle__button': true,
        'amui-button-toggle__button--active': option.value === selectedValue
      }"
    >
      <input
        :key="'input' + index + option.value"
        type="radio"
        :name="name"
        :value="option.value"
        :checked="option.value === selectedValue"
        @change="() => change(option.value)"
      />
      <div>
        <amui-icon
          v-if="option.icon !== undefined"
          :name="option.icon"
          size="lg"
          viewport="s"
          class="amui-button-toggle__icon"
        />
        <span class="amui-button-toggle__button-label" v-else>{{
          option.label
        }}</span>
      </div>
    </label>
  </div>
</template>
<script>
import { AmuiIcon } from '../icon'

export default {
  name: 'AmuiButtonToggle',

  components: {
    AmuiIcon
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: 'button-toggle'
    }
  },

  data() {
    return {
      resizeObserverInstance: null,
      buttonSizes: [],
      useAnimations: false
    }
  },

  computed: {
    activeElementProperties() {
      let properties = {
        offsetLeft: 0,
        width: 0
      }

      let index = this.options.findIndex(
        option => option.value === this.selectedValue
      )
      index = index === -1 ? 0 : index

      for (let i = 0; i <= index - 1; i++) {
        properties.offsetLeft += this.buttonSizes[i]
      }

      properties.width = this.buttonSizes[index]

      return properties
    },
    selectedValue() {
      let value

      if (this.value !== undefined) {
        // check for valid option value
        const foundOption = this.options.find(
          option => option.value === this.value
        )

        if (foundOption !== undefined) {
          value = foundOption.value
        }
      }

      if (value === undefined && this.options.length) {
        // use first entry of options if exist
        value = this.options[0].value
      }

      return value
    }
  },

  watch: {
    value(newValue, oldValue) {
      if (oldValue !== undefined) {
        this.useAnimations = true
      }
    }
  },

  mounted() {
    this.initResizeObserver()
    this.observeElement()
  },

  beforeDestroy() {
    this.resizeObserverInstance && this.resizeObserverInstance.disconnect()
  },

  methods: {
    change(value) {
      this.$emit('change', value)
    },
    initResizeObserver() {
      this.resizeObserverInstance = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            this.setButtonSizes()
          }
        }
      })
    },
    observeElement() {
      this.resizeObserverInstance &&
        this.resizeObserverInstance.observe(this.$el)
    },
    setButtonSizes() {
      this.buttonSizes = this.$refs.button.map(button => {
        return button.offsetWidth
      })
    }
  }
}
</script>
