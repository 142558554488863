<template>
  <div class="amui-efficiency-class-bars">
    <div class="amui-efficiency-class-bars__classes-column">
      <div class="amui-efficiency-class-bars__bars">
        <div
          v-for="efficiencyClass in efficiencyClasses"
          :key="efficiencyClass"
        >
          <div class="amui-efficiency-class-bars__bar-wrapper">
            <amui-efficiency-class-bar
              :value="efficiencyClass"
              :auto-width="false"
              class="amui-efficiency-class-bars__bar"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="amui-efficiency-class-bars__selected-class-column">
      <div
        v-if="efficiencyClassColumnLabel"
        class="amui-efficiency-class-bars__column-label"
      >
        {{ efficiencyClassColumnLabel }}
      </div>
      <div class="amui-efficiency-class-bars__bars">
        <div
          v-for="efficiencyClass in efficiencyClasses"
          :key="efficiencyClass"
        >
          <div class="amui-efficiency-class-bars__bar-wrapper">
            <amui-efficiency-class-bar
              v-if="efficiencyClass === selectedEfficiencyClass"
              :value="efficiencyClass"
              :auto-width="false"
              selected
              class="amui-efficiency-class-bars__selected-bar"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="selectedSecondaryEfficiencyClass"
      class="amui-efficiency-class-bars__selected-class-column"
    >
      <div
        v-if="secondaryEfficiencyClassColumnLabel"
        class="amui-efficiency-class-bars__column-label"
      >
        {{ secondaryEfficiencyClassColumnLabel }}
      </div>
      <div class="amui-efficiency-class-bars__bars">
        <div
          v-for="efficiencyClass in efficiencyClasses"
          :key="efficiencyClass"
        >
          <div class="amui-efficiency-class-bars__bar-wrapper">
            <amui-efficiency-class-bar
              v-if="efficiencyClass === selectedSecondaryEfficiencyClass"
              :value="efficiencyClass"
              :auto-width="false"
              selected
              class="amui-efficiency-class-bars__selected-bar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AMUI_EFFICIENCY_CLASSES } from './components/efficiency-class-bar/efficiency-class-bar'
import { AmuiEfficiencyClassBar } from './components/efficiency-class-bar'

export default {
  name: 'AmuiEfficiencyClassBars',

  components: {
    AmuiEfficiencyClassBar
  },

  data() {
    return {
      efficiencyClasses: AMUI_EFFICIENCY_CLASSES
    }
  },
  props: {
    efficiencyClass: {
      type: String,
      required: false
    },
    efficiencyClassColumnLabel: {
      type: String,
      required: false
    },
    secondaryEfficiencyClass: {
      type: String,
      required: false
    },
    secondaryEfficiencyClassColumnLabel: {
      type: String,
      required: false
    }
  },
  computed: {
    selectedEfficiencyClass() {
      return this.efficiencyClass
    },
    selectedSecondaryEfficiencyClass() {
      return this.secondaryEfficiencyClass
    }
  }
}
</script>
