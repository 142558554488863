<template>
  <div class="am-offer-configuration-rate-table">
    <div
      class="am-offer-configuration-rate-table__availability-info"
      v-if="hasUnavailableRates"
    >
      <div>
        <amui-icon
          name="info"
          size="sm"
          class="am-offer-configuration-rate-table__availability-info__icon"
        />
      </div>
      <span>
        {{
          $t('vdp.offer.configuration.leasing.table.partialAvailability.info')
        }}
        <span @click="$emit('request')">{{
          $t(
            'vdp.offer.configuration.leasing.table.partialAvailability.cta.label'
          )
        }}</span>
      </span>
    </div>
    <amui-card class="am-offer-configuration-rate-table--rounded">
      <table>
        <thead>
          <tr>
            <th>
              <div class="am-offer-configuration-rate-table__km-legend">
                {{ $t('vdp.offer.configuration.leasing.table.column.label') }}
                <amui-icon
                  name="arrow-right-alt"
                  size="sm"
                  class="am-offer-configuration-rate-table__icon"
                />
              </div>
            </th>
            <th v-for="mileage in formattedAnnualMileages" :key="mileage">
              {{ mileage }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="row.duration">
            <td>{{ row.duration }}</td>
            <td
              v-for="rate in row.rates"
              :key="rate.annualMileage + '' + rate.duration"
            >
              <template v-if="rate.price === false">
                <span class="am-offer-configuration-rate-table__not-available"
                  >–</span
                >
              </template>
              <template v-else-if="rate.price !== null"
                ><span
                  @click="onSelectRate(rate)"
                  :class="{
                    'am-offer-configuration-rate-table__rate': true,
                    'am-offer-configuration-rate-table__rate--selected':
                      rate.selected
                  }"
                  >{{
                    $t('vdp.offer.configuration.leasing.table.cell.value', {
                      value: rate.price
                    })
                  }}</span
                >
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </amui-card>
    <div class="am-offer-configuration-rate-table__annotate">
      <div class="am-offer-configuration-rate-table__annotate__item">
        <amui-icon
          name="arrow-down-alt"
          size="sm"
          class="am-offer-configuration-rate-table__icon"
        />
        {{ $t('vdp.offer.configuration.leasing.table.row.description') }}
      </div>
      <div class="am-offer-configuration-rate-table__annotate__item">
        <amui-icon
          name="arrow-right-alt"
          size="sm"
          class="am-offer-configuration-rate-table__icon"
        />
        {{ $t('vdp.offer.configuration.leasing.table.column.description') }}
      </div>
    </div>
  </div>
</template>

<script>
import { unique } from './../../../../../../utils/array'
import { formatPrice, formatMileage } from './../../../../../../utils/formatter'
import { getNewRateByAdjustedDownPayment } from './../../../../../../utils/down-payment'

import AmuiCard from '../../../../../../ui/components/card/card.vue'
import AmuiIcon from '../../../../../../ui/components/icon/icon.vue'

export default {
  name: 'AmOfferConfigurationRateTable',

  components: {
    AmuiCard,
    AmuiIcon
  },

  props: {
    selectedRate: {
      type: Number,
      required: true
    },
    downPayment: {
      type: Number,
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    /**
     * the calculated rate must have at least this value.
     * value is expected in cents
     */
    minRate: {
      type: Number,
      required: false,
      default: 1000
    }
  },

  computed: {
    annualMileages() {
      const annualMileages = unique(this.values.map(v => v.annualMileage))

      annualMileages.sort((a, b) => {
        return a - b
      })

      return annualMileages
    },
    formattedAnnualMileages() {
      return this.annualMileages.map(a => formatMileage(a))
    },
    rows() {
      const durations = unique(this.values.map(v => v.duration))

      durations.sort((a, b) => {
        return a - b
      })

      return durations.map(duration => {
        const rates = []

        this.annualMileages.forEach(annualMileage => {
          const found = this.values.find(value => {
            return (
              value.duration === duration &&
              value.annualMileage === annualMileage
            )
          })

          if (found !== undefined) {
            const price = getNewRateByAdjustedDownPayment(
              this.downPayment,
              found.downPayment,
              found.price,
              duration
            )

            if (price >= this.minRate) {
              rates.push({
                price: formatPrice(price),
                selected: this.selectedRate === price,
                annualMileage,
                duration
              })
            } else {
              rates.push({
                price: false, // only for current down payment not available
                selected: false,
                annualMileage,
                duration
              })
            }
          } else {
            rates.push({
              price: null, // not available at all
              selected: false,
              annualMileage,
              duration
            })
          }
        })

        return {
          duration: this.$tc(
            'vdp.offer.configuration.leasing.table.row.label',
            duration,
            {
              month: duration
            }
          ),
          rates: rates
        }
      })
    },
    hasUnavailableRates() {
      return (
        this.rows.find(r => {
          let found = false
          r.rates.forEach(rr => {
            if (rr.price === false) {
              found = true
            }
          })
          return found
        }) !== undefined
      )
    }
  },

  methods: {
    onSelectRate(rate) {
      this.$emit('change', rate)
    }
  }
}
</script>
