<template>
  <div class="am-offer-configuration-duration-select">
    <div class="am-offer-configuration-duration-select__label">
      {{ label }}
    </div>
    <div class="am-offer-configuration-duration-select__items">
      <amui-chip
        v-for="option in options"
        :key="option.value"
        :label="option.label"
        :selected="option.value === selectedValue"
        :selectable="true"
        :disabled="option.disabled || false"
        @update:selected="onChange(option.value)"
        class="am-offer-configuration-duration-select__item"
      />
    </div>
  </div>
</template>

<script>
import AmuiChip from '../../../../../../ui/components/chip/chip.vue'

export default {
  name: 'AmOfferConfigurationDurationSelect',

  model: {
    prop: 'value',
    event: 'change'
  },

  components: {
    AmuiChip
  },

  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },

    value: {
      type: Number,
      required: false,
      default: null
    },

    label: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      selectedValue: this.value
    }
  },

  watch: {
    value(value) {
      this.selectedValue = value
    }
  },

  methods: {
    onChange(value) {
      this.selectedValue = value
      this.$emit('change', value)
    }
  }
}
</script>
