<template>
  <component :is="htmlTag" :href="url" class="amui-card" :class="classes">
    <slot></slot>
  </component>
</template>

<script>
const AMUI_BACKGROUND_VALID_COLOR = ['cloud', 'transparent']
export default {
  name: 'AmuiCard',

  props: {
    url: {
      type: String,
      required: false,
      default: null
    },
    bordered: {
      type: Boolean,
      required: false,
      default: false
    },
    inlineBlock: {
      type: Boolean,
      required: false,
      default: false
    },
    shadowed: {
      type: Boolean,
      required: false,
      default: false
    },
    backgroundColor: {
      type: String,
      require: false,
      validator(value) {
        return AMUI_BACKGROUND_VALID_COLOR.includes(value)
      }
    }
  },

  computed: {
    classes() {
      return {
        'amui-card--bordered': this.bordered && !this.shadowed,
        'amui-card--shadowed': this.shadowed && !this.bordered,
        'amui-card--inline-block': this.inlineBlock,
        ['amui-card--bg-' + this.backgroundColor]: this.backgroundColor
      }
    },
    htmlTag() {
      return this.url !== null ? 'a' : 'div'
    }
  }
}
</script>
