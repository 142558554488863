<template>
  <div class="am-vehicle-player-navigation">
    <div>
      <button
        :class="{
          'am-vehicle-player-navigation__control': true,
          'am-vehicle-player-navigation__control--disabled': !hasPreviousItem
        }"
        :aria-label="navigateBackwardLabel"
        :disabled="!hasPreviousItem"
        @click="onClickPrevious"
      >
        <amui-icon name="arrow-slide-left" />
      </button>
    </div>
    <div>
      <button
        v-if="availableTypes.includes('gallery')"
        :class="{
          'am-vehicle-player-navigation__control': true,
          'am-vehicle-player-navigation__control--inactive':
            itemType !== 'gallery'
        }"
        :aria-label="navigateToGalleryLabel"
        @click="onClickGallery"
      >
        <div class="am-vehicle-player-navigation__image-counter">
          <amui-icon name="image" />
          <span>{{ currentImageNumber }}/{{ totalImagesCount }}</span>
        </div>
      </button>
      <button
        v-if="availableTypes.includes('rotation')"
        :class="{
          'am-vehicle-player-navigation__control': true,
          'am-vehicle-player-navigation__control--inactive':
            itemType !== 'rotation'
        }"
        :aria-label="navigateToRotationLabel"
        @click="onClickRotation"
      >
        <amui-icon name="360-rotation" />
      </button>
      <button
        v-if="availableTypes.includes('interior')"
        :class="{
          'am-vehicle-player-navigation__control': true,
          'am-vehicle-player-navigation__control--inactive':
            itemType !== 'interior'
        }"
        :aria-label="navigateToInteriorLabel"
        @click="onClickInterior"
      >
        <amui-icon name="360-interior" />
      </button>
    </div>
    <div>
      <button
        :class="{
          'am-vehicle-player-navigation__control': true,
          'am-vehicle-player-navigation__control--disabled': !hasNextItem
        }"
        :aria-label="navigateForwardLabel"
        :disabled="!hasNextItem"
        @click="onClickNext"
      >
        <amui-icon name="arrow-slide-right" />
      </button>
    </div>
  </div>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'

export default {
  name: 'AmVehiclePlayerNavigation',

  components: {
    AmuiIcon
  },

  model: {
    event: 'change',
    prop: 'value'
  },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    navigateForwardLabel: {
      type: String,
      required: true
    },
    navigateBackwardLabel: {
      type: String,
      required: true
    },
    navigateToGalleryLabel: {
      type: String,
      required: true
    },
    navigateToRotationLabel: {
      type: String,
      required: true
    },
    navigateToInteriorLabel: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      timer: null
    }
  },

  computed: {
    hasPreviousItem() {
      return this.items.findIndex(item => item.id === this.value) > 0
    },
    hasNextItem() {
      return (
        this.items.findIndex(item => item.id === this.value) <
        this.items.length - 1
      )
    },
    itemType() {
      let type = null

      const item = this.items.find(item => item.id === this.value)

      if (item !== undefined) {
        type = item.type
      }

      return type
    },
    availableTypes() {
      return this.items.map(item => item.type)
    },
    currentImageNumber() {
      const itemIndex = this.items.findIndex(item => item.id === this.value)
      return itemIndex + 1
    },
    totalImagesCount() {
      return this.items.filter(item => item.type === 'gallery').length
    }
  },

  methods: {
    onClickPrevious() {
      const currentIndex = this.items.findIndex(item => item.id === this.value)

      if (this.items[currentIndex - 1]) {
        this.$emit('change', this.items[currentIndex - 1].id)
      }
    },
    onClickNext() {
      const currentIndex = this.items.findIndex(item => item.id === this.value)

      if (this.items[currentIndex + 1]) {
        this.$emit('change', this.items[currentIndex + 1].id)
      }
    },
    onClickGallery() {},
    onClickRotation() {},
    onClickInterior() {}
  }
}
</script>

<style></style>
