import Card from './card'
import CardSection from './components/card-section/card-section'

export const AmuiCard = Card
export const AmuiCardSection = CardSection

export default Vue => {
  Vue.component(Card.name, Card)
  Vue.component(CardSection.name, CardSection)
}
