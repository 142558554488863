<template>
  <div class="am-vehicle-player-image-stage">
    <amui-image
      :sources="sources"
      :fallback-source="fallbackSource"
      :alt="alt"
      ratio="3:2"
      object-fit="contain"
      :loading="loading"
    />
  </div>
</template>

<script>
import { AmuiImage } from '@/../ui/components/image'

export default {
  name: 'AmVehiclePlayerImageStage',

  components: { AmuiImage },

  props: {
    sources: {
      type: Array,
      required: true,
      validator(sources) {
        return sources.every(
          source => 'media' in source && 'srcset' in source && source.srcset
        )
      }
    },
    fallbackSource: {
      type: String,
      required: false,
      default: null
    },
    loading: {
      type: String,
      required: false,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
