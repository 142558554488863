<template>
  <div class="am-highlighted-vehicle-features">
    <div v-for="feature in features" :key="feature.label">
      <amui-icon
        :name="feature.icon"
        :class="{
          'am-highlighted-vehicle-features__icon': true,
          ['am-highlighted-vehicle-features__icon--' +
          (feature.iconColor || '').toLowerCase()]: feature.iconColor
        }"
      />{{ feature.label }}
    </div>
  </div>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'

export default {
  name: 'AmHighlightedVehicleFeatures',

  components: {
    AmuiIcon
  },

  props: {
    features: {
      type: Array,
      required: true
    }
  }
}
</script>
