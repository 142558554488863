<template>
  <div class="amui-fp-content-layout">
    <div class="amui-fp-content-layout__header">
      <div class="amui-fp-content-layout__row">
        <div class="amui-fp-content-layout__row-label">
          {{ header.label }}
        </div>
        <div class="amui-fp-content-layout__row-value">
          {{ header.value }}
        </div>
      </div>
    </div>
    <div class="amui-fp-content-layout__body">
      <div
        class="amui-fp-content-layout__row amui-fp-content-layout__row--head"
        v-for="data in body"
        :key="data.label"
      >
        <div class="amui-fp-content-layout__row-label">
          {{ data.label }}
        </div>
        <div
          v-if="data.value !== null"
          class="amui-fp-content-layout__row-value"
        >
          {{ data.value }}
        </div>
      </div>
    </div>
    <div class="amui-fp-content-layout__foot-note">
      <div
        class="amui-fp-content-layout__row amui-fp-content-layout__row--head"
        v-for="note in footNotes"
        :key="note"
      >
        <div class="amui-fp-content-layout__row-label">
          {{ note }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmuiFinancingPopoverContentLayout',

  props: {
    header: {
      type: Object,
      required: true
    },
    body: {
      type: Array,
      required: true
    },
    footNotes: {
      type: Array,
      required: true
    }
  }
}
</script>
