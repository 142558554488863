<template>
  <div
    class="amui-textarea"
    :class="{
      'amui-textarea--responsive': viewport === null
    }"
  >
    <div
      class="amui-textarea__wrapper"
      :class="{
        'amui-textarea__wrapper--focus': focused
      }"
    >
      <textarea
        :rows="rows"
        :value="value"
        :placeholder="placeholder"
        @focus="focused = true"
        @blur="focused = false"
        @input="$emit('input', $event.target.value)"
        class="amui-textarea__textarea"
      ></textarea>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AmuiTextarea',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    rows: {
      type: Number,
      required: false
    },
    viewport: {
      type: String,
      default: null,
      validate(value) {
        return ['s'].includes(value)
      }
    }
  },
  data() {
    return {
      focused: false
    }
  }
}
</script>
