<template>
  <div class="am-vehicle-detail-information">
    <div class="am-vehicle-detail-information__header">
      <div>{{ vehicleTitle }}</div>
      <button aria-label="Overlay schließen" @click="$emit('close')">
        <amui-icon name="close" class="am-icon-size-md" />
      </button>
    </div>
    <div class="am-vehicle-detail-information__content">
      <div>
        <amui-headline html-tag="h3" looks-like="h6"
          >{{ $t('vdp.detailInformation.consumptionProperties.headline')
          }}<sup>1</sup></amui-headline
        >
        <div class="am-vehicle-detail-information__consumption">
          <div>
            <am-phev-consumption-and-emissions-view
              v-if="isPHEV"
              :raw-vehicle-data="rawVehicleData"
            />
            <am-bev-consumption-and-emissions-view
              v-else-if="isBEV"
              :raw-vehicle-data="rawVehicleData"
            />
            <am-ice-consumption-and-emissions-view
              v-else
              :raw-vehicle-data="rawVehicleData"
            />
          </div>
        </div>
      </div>
      <div class="am-vehicle-detail-information__technical">
        <amui-headline html-tag="h3" looks-like="h6">{{
          $t('vdp.detailInformation.technicalProperties.headline')
        }}</amui-headline>
        <amui-content-collapsable
          :label-collapsed="
            $t('vdp.detailInformation.collapsable.label.collapsed')
          "
        >
          <div
            class="am-vehicle-detail-information__technical-properties-wrapper"
          >
            <div class="am-vehicle-detail-information__properties">
              <div
                v-for="(property, index) in technicalProperties"
                :key="property.label + index"
              >
                <div>{{ property.label }}</div>
                <div>{{ property.value }}</div>
              </div>
            </div>
          </div>
        </amui-content-collapsable>
      </div>
      <div
        v-if="historyProperties.length"
        class="am-vehicle-detail-information__history"
      >
        <amui-headline html-tag="h3" looks-like="h6">{{
          $t('vdp.detailInformation.historyProperties.headline')
        }}</amui-headline>
        <amui-content-collapsable
          :label-collapsed="
            $t('vdp.detailInformation.collapsable.label.collapsed')
          "
        >
          <div
            class="am-vehicle-detail-information__history-properties-wrapper"
          >
            <div class="am-vehicle-detail-information__properties">
              <div
                v-for="(property, index) in historyProperties"
                :key="property.label + index"
              >
                <div>{{ property.label }}</div>
                <div>{{ property.value }}</div>
              </div>
            </div>
          </div>
        </amui-content-collapsable>
      </div>
      <div class="am-vehicle-detail-information__equipment">
        <amui-headline html-tag="h3" looks-like="h6">{{
          $t('vdp.detailInformation.equipmentFeatures.headline')
        }}</amui-headline>
        <amui-content-collapsable
          :label-collapsed="
            $t('vdp.detailInformation.collapsable.label.collapsed')
          "
        >
          <div class="am-vehicle-detail-information__equipment-items">
            <div
              v-for="c in categorizedFeatures"
              :key="c.headline"
              class="am-vehicle-detail-information__equipment-item"
            >
              <div>{{ c.headline }}</div>
              <div>
                <div v-for="(feature, index) in c.list" :key="feature + index">
                  {{ feature }}
                </div>
              </div>
            </div>
          </div>
        </amui-content-collapsable>
      </div>
      <div class="am-vehicle-detail-information__description">
        <amui-headline html-tag="h3" looks-like="h6">{{
          $t('vdp.detailInformation.description.headline')
        }}</amui-headline>
        <amui-content-collapsable
          :label-collapsed="
            $t('vdp.detailInformation.collapsable.label.collapsed')
          "
        >
          <div
            v-html="description"
            class="am-vehicle-detail-information__description-items"
          ></div>
        </amui-content-collapsable>
      </div>

      <div>
        <div class="am-vehicle-detail-information__legal">
          1.
          <amui-i18n :term="$t('legal.consumptionAndEmission')" tag="span">
            <a slot="link" href="https://www.dat.de/co2/" target="_blank">
              https://www.dat.de/co2/
            </a>
          </amui-i18n>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiI18n } from '@/../ui/components/i18n'
import { AmuiContentCollapsable } from '@/../ui/components/content-collapsable'
import { AmuiHeadline } from '@/../ui/components/headline'

import { categorizedFeaturesPropertyList } from '@/shared/definitions/vehicle-features.js'
import { technicalPropertyList } from '@/shared/definitions/vehicle-technical.js'
import { historyPropertyList } from '@/shared/definitions/vehicle-history.js'

import { findDeepPropertyValue } from '@/../utils/object.js'
import { formatCount, formatMileage } from '@/../utils/formatter.js'
import { getTitle as getVehicleTitle } from '@/../utils/vehicle'

import AmPhevConsumptionAndEmissionsView from './components/consumption-and-emissions/phev.vue'
import AmBevConsumptionAndEmissionsView from './components/consumption-and-emissions/bev.vue'
import AmIceConsumptionAndEmissionsView from './components/consumption-and-emissions/ice.vue'

export default {
  name: 'AmVehicleDetailInformation',

  components: {
    AmuiIcon,
    AmuiI18n,
    AmuiContentCollapsable,
    AmuiHeadline,
    AmPhevConsumptionAndEmissionsView,
    AmBevConsumptionAndEmissionsView,
    AmIceConsumptionAndEmissionsView
  },

  props: {
    rawVehicleData: {
      type: Object,
      required: true
    }
  },

  computed: {
    vehicleTitle() {
      let title = null

      if (this.rawVehicleData) {
        title = getVehicleTitle(this.rawVehicleData)
      }

      return title
    },
    technicalProperties() {
      return this.getTranslatedPropertyList(technicalPropertyList)
    },
    historyProperties() {
      return this.getTranslatedPropertyList(historyPropertyList)
    },
    categorizedFeatures() {
      return Object.keys(categorizedFeaturesPropertyList)
        .map(categoryId => ({
          headline: this.$t('vdp.featureCategory.' + categoryId),
          list: this.getTranslatedFeatureList(
            categorizedFeaturesPropertyList[categoryId],
            this.rawVehicleData
          )
        }))
        .filter(obj => obj.list.length > 0)
    },
    description() {
      const { description } = this.rawVehicleData
      const isCreole = String(description || '').indexOf('\\') !== -1
      let html = description || ''
      html = html.replace(/(\*\s.*?)\\{4}/gm, '<ul>$1</ul>')
      html = html.replace(/\*\s(.*?)\\{2}/g, '<li>$1</li>')
      //html = html.replace(/(<li>.*?<br>)/g, '<ul>$1</ul>')
      html = html.replace(/\\+/g, '<br>')
      html = html.replace(/----/g, '<br>')
      html = html.replace(/\*\*(.*?)\*\*/gi, '<h4>$1</h4>')
      if (!isCreole) html = html.replace(/,\s/g, ',<br>')

      return html
    },
    isPHEV() {
      return this.rawVehicleData.hybridPlugin === true
    },
    isBEV() {
      return this.rawVehicleData.fuel === 'ELECTRICITY'
    }
  },
  methods: {
    getTranslatedFeatureList(properties, vehicleObject) {
      const list = []
      properties.forEach(property => {
        if (
          typeof property === 'object' &&
          'key' in property &&
          'value' in property
        ) {
          // used for single- or multiselect properties to display only a certain value
          if (
            (Array.isArray(vehicleObject[property.key]) &&
              vehicleObject[property.key].includes(property.value)) ||
            (typeof vehicleObject[property.key] === 'string' &&
              vehicleObject[property.key] === property.value)
          ) {
            list.push(this.$t('vehicle.' + property.key + '' + property.value))
          }
        } else if (Array.isArray(vehicleObject[property])) {
          // used for multiselect fields to display all available array entries as features at once
          vehicleObject[property].forEach(propertyValue => {
            list.push(this.$t('vehicle.' + property + '' + propertyValue))
          })
        } else if (typeof vehicleObject[property] === 'string') {
          // used for singleselect fields to display the selected value
          list.push(
            this.$t('vehicle.' + property + '' + vehicleObject[property])
          )
        } else if (vehicleObject[property] !== undefined) {
          // used for all other cases, mostly that would be truthy boolean properties
          list.push(this.$t('vehicle.' + property))
        }
      })
      return list
    },
    getTranslatedPropertyList(properties) {
      const defaultPropertyObject = {
        key: null,
        value: null
      }
      const data = []

      const { vehicleClass } = this.rawVehicleData

      properties.forEach(property => {
        //normalize property input to an object structure
        property = Object.assign(
          {},
          defaultPropertyObject,
          typeof property === 'string' ? { key: property } : property
        )

        let propertyValue = findDeepPropertyValue(
          this.rawVehicleData,
          property.key
        )

        if (propertyValue !== undefined) {
          if (property.key === 'cubicCapacity') {
            data.push({
              label: this.getPropertyLabelTranslation(property.key),
              value: this.getPropertyValueTranslation(property.key, {
                cubicCapacity: propertyValue
              })
            })
          } else if (property.key === 'power') {
            data.push({
              label: this.getPropertyLabelTranslation(property.key),
              value: this.getPropertyValueTranslation(property.key, {
                kw: parseInt(propertyValue),
                ps: Math.round(parseInt(propertyValue) * 1.35962)
              })
            })
          } else if (property.key === 'dimension.width') {
            data.push({
              label: this.getPropertyLabelTranslation(property.key),
              value: this.getPropertyValueTranslation(property.key, {
                value: formatCount(propertyValue)
              })
            })
          } else if (property.key === 'dimension.length') {
            data.push({
              label: this.getPropertyLabelTranslation(property.key),
              value: this.getPropertyValueTranslation(property.key, {
                value: formatCount(propertyValue)
              })
            })
          } else if (property.key === 'dimension.height') {
            data.push({
              label: this.getPropertyLabelTranslation(property.key),
              value: this.getPropertyValueTranslation(property.key, {
                value: formatCount(propertyValue)
              })
            })
          } else if (property.key === 'amPayload') {
            data.push({
              label: this.getPropertyLabelTranslation(property.key),
              value: this.getPropertyValueTranslation(property.key, {
                value: formatCount(propertyValue)
              })
            })
          } else if (property.key === 'amWheelbase') {
            data.push({
              label: this.getPropertyLabelTranslation(property.key),
              value: this.getPropertyValueTranslation(property.key, {
                value: formatCount(propertyValue)
              })
            })
          } else if (property.key === 'amCurbWeight') {
            data.push({
              label: this.getPropertyLabelTranslation(property.key),
              value: this.getPropertyValueTranslation(property.key, {
                value: formatCount(propertyValue)
              })
            })
          } else if (property.key === 'amLadenWeight') {
            data.push({
              label: this.getPropertyLabelTranslation(property.key),
              value: this.getPropertyValueTranslation(property.key, {
                value: formatCount(propertyValue)
              })
            })
          } else if (
            ['accidentDamaged', 'damageUnrepaired'].includes(property.key)
          ) {
            if (propertyValue) {
              data.push({
                label: this.getPropertyLabelTranslation(property.key),
                value: this.getPropertyValueTranslation(
                  property.key,
                  propertyValue ? 'YES' : 'NO'
                )
              })
            }
          } else if (property.key === 'roadworthy') {
            if (
              this.rawVehicleData.accidentDamaged ||
              this.rawVehicleData.damageUnrepaired
            ) {
              if (propertyValue) {
                data.push({
                  label: this.getPropertyLabelTranslation(property.key),
                  value: this.getPropertyValueTranslation(
                    property.key,
                    propertyValue
                  )
                })
              }
            }
          } else if (property.key === 'firstRegistration') {
            if (propertyValue) {
              data.push({
                label: this.getPropertyLabelTranslation(property.key),
                value: this.getPropertyValueTranslation(property.key, {
                  year: propertyValue.substring(0, 4),
                  month: propertyValue.substring(4, 6)
                })
              })
            }
          } else if (property.key === 'mileage') {
            if (propertyValue) {
              data.push({
                label: this.getPropertyLabelTranslation(property.key),
                value: this.getPropertyValueTranslation(property.key, {
                  mileage: formatMileage(propertyValue)
                })
              })
            }
          } else if (property.key === 'generalInspection') {
            if (propertyValue) {
              data.push({
                label: this.getPropertyLabelTranslation(property.key),
                value: this.getPropertyValueTranslation(property.key, {
                  year: propertyValue.substring(0, 4),
                  month: propertyValue.substring(4, 6)
                })
              })
            }
          } else if (property.key === 'condition') {
            if (propertyValue) {
              // TODO: use getPropertyLabelTranslation/getPropertyValueTranslation with a i18n namespace option
              data.push({
                label: this.$t('vehicle.condition'),
                value: this.$t(
                  'vehicleClass.' + vehicleClass + '.condition' + propertyValue
                )
              })
            }
          } else if (property.key === 'category') {
            if (propertyValue) {
              // TODO: use getPropertyLabelTranslation/getPropertyValueTranslation with a i18n namespace option
              data.push({
                label: this.$t('vehicleClass.' + vehicleClass + '.category'),
                value: this.$t(
                  'vehicleClass.' + vehicleClass + '.category' + propertyValue
                )
              })
            }
          } else {
            if (typeof property.value === 'string') {
              // edge case: if property key + value is set, the value will be used as label, and the value will be a boolean
              data.push({
                label: this.getPropertyLabelTranslation(
                  property.key,
                  property.value
                ),
                value: this.getPropertyValueTranslation(
                  property.key,
                  (Array.isArray(propertyValue)
                    ? propertyValue
                    : [propertyValue]
                  ).includes(property.value)
                )
              })
            } else {
              // determine translation based on property data value
              data.push({
                label: this.getPropertyLabelTranslation(property.key),
                value: this.getPropertyValueTranslation(
                  property.key,
                  propertyValue
                )
              })
            }
          }
        }
      })

      return data
    },
    getPropertyLabelTranslation(property, propertyValue) {
      return this.$t(
        'vehicle.' +
          property +
          (typeof propertyValue === 'string' ? propertyValue : '')
      )
    },
    getPropertyValueTranslation(property, arg2, arg3) {
      const isObject = v => typeof v === 'object' && !Array.isArray(v)
      let translation
      const propertyValue = isObject(arg2) ? undefined : arg2
      const replacements = isObject(arg2) ? arg2 : arg3

      if (typeof replacements === 'object') {
        translation = this.$t('vehicle.' + property + 'TEXT', replacements)
      } else if (typeof propertyValue === 'boolean') {
        translation = this.$t(
          'vdp.detailInformation.properties.boolean.' +
            (propertyValue ? 'yes' : 'no')
        )
      } else if (typeof propertyValue === 'number') {
        translation = propertyValue
      } else if (Array.isArray(propertyValue)) {
        translation = propertyValue
          .map(val => this.$t('vehicle.' + property + val))
          .join(', ')
      } else if (typeof propertyValue === 'string') {
        translation = this.$t('vehicle.' + property + propertyValue)
      } else {
        translation = propertyValue
      }

      return translation
    }
  }
}
</script>
