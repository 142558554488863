<template>
  <div
    class="am-vehicle-player-thumbnail-image"
    :class="{
      'am-vehicle-player-thumbnail-image--active': active
    }"
  >
    <picture>
      <source
        v-for="(source, i) in sources"
        :key="i"
        :media="source.media"
        :srcset="source.srcset"
      />
      <img :src="fallbackSource.srcset" draggable="false" :alt="alt" />
    </picture>
  </div>
</template>

<script>
export default {
  name: 'AmVehiclePlayerThumbnail',

  props: {
    sources: {
      type: Array,
      required: false,
      default: () => []
    },
    fallbackSource: {
      type: String,
      required: false,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: null
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style></style>
