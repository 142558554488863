<template>
  <div
    class="am-configuration-option"
    :class="cssClasses"
    @click="$emit('click')"
  >
    <div>
      <div class="am-configuration-option__header">
        <div class="am-configuration-option__label-wrapper">
          <amui-headline
            html-tag="h2"
            looks-like="h6"
            class="am-configuration-option__label"
            ><amui-icon
              name="check"
              class="am-configuration-option__label-icon"
            ></amui-icon
            >{{ label }}</amui-headline
          >
          <span v-if="linkLabel && !active">{{ linkLabel }}</span>
        </div>
        <slot name="header"></slot>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiHeadline } from '@/../ui/components/headline'

export default {
  name: 'AmConfigurationOption',

  components: {
    AmuiIcon,
    AmuiHeadline
  },

  props: {
    label: {
      type: String,
      required: true
    },
    linkLabel: {
      type: String,
      required: false,
      default: null
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    cssClasses() {
      return {
        'am-configuration-option--active': this.active
      }
    }
  }
}
</script>
