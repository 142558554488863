<template>
  <div class="vehicle-player-stage">
    <div class="vehicle-player-stage__types">
      <am-vehicle-player-gallery-stage
        v-if="stageType === 'gallery'"
        :items="galleryItems"
        :value="value"
        @change="value => $emit('change', value)"
      ></am-vehicle-player-gallery-stage>
    </div>
  </div>
</template>

<script>
import AmVehiclePlayerGalleryStage from './components/gallery/vehicle-player-gallery-stage.vue'

export default {
  name: 'AmVehiclePlayerStage',

  components: {
    AmVehiclePlayerGalleryStage
  },

  model: {
    event: 'change',
    prop: 'value'
  },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String,
      required: false,
      default: null
    }
  },

  computed: {
    stageType() {
      let type = null

      const item = this.items.find(item => item.id === this.value)

      if (item !== undefined) {
        type = item.type
      }

      return type
    },
    galleryItems() {
      return this.items.filter(item => item.type === 'gallery')
    }
  }
}
</script>

<style></style>
