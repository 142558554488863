<template>
  <div class="am-checkout-footer">
    <amui-button
      :label="backButtonLabel"
      type="secondary"
      @click="$emit('back')"
    />
    <amui-button
      v-if="nextButtonLabel"
      :label="nextButtonLabel"
      icon-right="arrow-slide-right"
      @click="$emit('next')"
    />
  </div>
</template>

<script>
import { AmuiButton } from '@/../ui/components/button'

export default {
  name: 'AmCheckoutFooter',

  components: {
    AmuiButton
  },

  props: {
    backButtonLabel: {
      type: String,
      required: true
    },
    nextButtonLabel: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
