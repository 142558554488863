import { client } from './../../shared/http/mpc-client'

export const saveLandingPageLead = (companyId, lead) => {
  return client
    .post('/graphql', {
      query: `
        mutation createLandingPageLead($_company: ID!, $lead: JSON!) {
            createLandingPageLead(_company: $_company, lead: $lead)
        }
      `,
      variables: {
        _company: companyId,
        lead: lead
      }
    })
    .then(res => {
      return res.data.data.createLandingPageLead
    })
}

export const saveTestDriveLead = (companyId, lead) => {
  return client
    .post('/graphql', {
      query: `
        mutation CreateTestDriveLead($_company: ID!, $lead: JSON!) {
            createTestDriveLead(_company: $_company, lead: $lead)
        }
      `,
      variables: {
        _company: companyId,
        lead: lead
      }
    })
    .then(res => {
      return res.data.data.createTestDriveLead
    })
}

export default {
  saveLandingPageLead,
  saveTestDriveLead
}
