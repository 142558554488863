import { formatPrice } from '../../../../../utils/formatter.js'
import legalMixin from './legal.mixin.js'

export default {
  mixins: [legalMixin],

  methods: {
    getLegalTextForClosingCosts(value) {
      return value !== undefined
        ? this.$t('vdp.offer.configuration.financing.legal.closingCosts', {
            value: formatPrice(value, true)
          })
        : null
    },
    getLegalTextForPaymentProtectionInsurance(value) {
      return value !== undefined
        ? this.$t(
            'vdp.offer.configuration.financing.legal.paymentProtectionInsurance',
            {
              value: formatPrice(value, true)
            }
          )
        : null
    },
    getLegalTextForTypeOfNominalInterestRate(type) {
      let text = null

      if (type === 'BOUND') {
        text = this.$t(
          'vdp.offer.configuration.financing.legal.typeOfNominalInterestRate.bound'
        )
      } else if (type === 'VARIABLE') {
        text = this.$t(
          'vdp.offer.configuration.financing.legal.typeOfNominalInterestRate.variable'
        )
      } else if (type === 'COMBINED') {
        text = this.$t(
          'vdp.offer.configuration.financing.legal.typeOfNominalInterestRate.combined'
        )
      }

      return text
    },
    getFinancingOfferLegalText(offer) {
      return this.getCleanedLegalText(
        this.$t('vdp.offer.configuration.financing.legal.template', {
          bank: this.$t('vdp.offer.configuration.financing.legal.bank', {
            value: offer.bank
          }),
          annualPercentageRate: this.$t(
            'vdp.offer.configuration.financing.legal.annualPercentageRate',
            { value: offer.annualPercentageRate }
          ),
          paybackPeriod: this.$t(
            'vdp.offer.configuration.financing.legal.paybackPeriod',
            { value: offer.paybackPeriod }
          ),
          firstInstallment: this.$t(
            'vdp.offer.configuration.financing.legal.firstInstallment',
            { value: formatPrice(offer.firstInstallment, true) }
          ),
          finalInstallment: this.$t(
            'vdp.offer.configuration.financing.legal.finalInstallment',
            { value: formatPrice(offer.finalInstallment, true) }
          ),
          nominalInterestRate: this.$t(
            'vdp.offer.configuration.financing.legal.nominalInterestRate',
            { value: offer.nominalInterestRate }
          ),
          typeOfNominalInterestRate: this.getLegalTextForTypeOfNominalInterestRate(
            offer.typeOfNominalInterestRate
          ),
          paymentProtectionInsurance:
            this.getLegalTextForPaymentProtectionInsurance(
              offer.paymentProtectionInsurance
            ) || '',
          closingCosts:
            this.getLegalTextForClosingCosts(offer.closingCosts) || '',
          grossLoanAmount: this.$t(
            'vdp.offer.configuration.financing.legal.grossLoanAmount',
            { value: formatPrice(offer.grossLoanAmount, true) }
          ),
          netLoanAmount: this.$t(
            'vdp.offer.configuration.financing.legal.netLoanAmount',
            { value: formatPrice(offer.netLoanAmount, true) }
          ),
          additionalCosts: this.getLegalTextForDestinationChargeAndRegistrationFees(
            offer.destinationCharges,
            offer.registrationFees
          ),
          conditions: offer.conditions || '',
          vatInfo: this.$t('vdp.offer.configuration.legal.vatInfo.inclusive')
        })
      )
    }
  }
}
