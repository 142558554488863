<template>
  <div class="am-checkout-autouncle">
    <template v-if="hasAutouncleConsent">
      <div id="au-trade-in-calculator">
        <iframe :src="getWidgetUrl()" title="Autouncle Ankaufformular"></iframe>
      </div>
    </template>
    <template v-else>
      Der Service "Autouncle Aps" wurde über den Cookie Banner bislang nicht
      akzeptiert. Ändere die Einstellungen der Cookies und erlaube den Service
      "Autounce Aps" um das Formular für den Fahrzeugankauf angezeigt zu
      bekommen.
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AmCheckoutAutouncle',

  props: {
    vehicleObject: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      settings: null,
      host: 'https://www.autouncle.de',
      ratingUrl: '/widgets/trade_in_valuations/new'
    }
  },

  computed: {
    ...mapGetters('core', ['getIntegrationByName']),
    ...mapGetters('consent', { consentAllowedServices: 'allowedServices' }),
    hasAutouncleConsent() {
      return this.consentAllowedServices.includes('autouncle')
    },
    autouncleTradeInIntegration() {
      return this.getIntegrationByName(
        'autouncle-trade-in',
        this.vehicleObject.mobileSellerId
      )
    },
    vehicleUrl() {
      let url = this.$router.resolve({
        name: 'detail',
        params: {
          id: this.vehicleObject.mobileAdId
        }
      }).href

      if (
        this.autouncleTradeInIntegration &&
        this.autouncleTradeInIntegration.settings?.vehicleUrl
      ) {
        url = this.autouncleTradeInIntegration.settings.vehicleUrl.replace(
          '{mobileAdId}',
          this.vehicleObject.mobileAdId
        )
      }
      return url
    },
    departmentId() {
      return this.autouncleTradeInIntegration?.settings?.departmentId || null
    }
  },
  methods: {
    getWidgetUrl: function() {
      return this.host + this.ratingUrl + '?' + this.getWidgetParams()
    },
    getWidgetParams: function() {
      const params = new URLSearchParams()
      params.append('c_id', this.autouncleTradeInIntegration?.settings?.id)
      params.append('hide_au_logo', '1')
      params.append(
        'data_from_source',
        JSON.stringify({
          integrationType: 'inline-form',
          stockId: this.vehicleObject.internalNumber
        })
      )
      params.append(
        'd',
        JSON.stringify({
          device: window.matchMedia('only screen and (max-width: 760px)')
            .matches
            ? 'mobile'
            : 'desktop',
          depId: this.departmentId,
          url: this.vehicleUrl
        })
      )
      params.append('car_url', this.vehicleUrl)
      params.append('parent_id', 'au-trade-in-calculator')
      params.append('initialWidth', '100%')
      params.append('childId', 'au-trade-in-calculator')
      return params.toString()
    }
  }
}
</script>
