<template>
  <div class="am-vehicle-player-gallery-stage" :class="classes">
    <div class="am-vehicle-player-gallery-stage__items-wrapper" ref="boundary">
      <div class="am-vehicle-player-gallery-stage__items" ref="items">
        <template v-for="(item, i) in enrichedItems">
          <am-vehicle-player-image-stage
            ref="item"
            :key="'image-' + i"
            v-if="item.data.type === 'image'"
            :sources="item.data.sources"
            :fallback-source="item.data.fallbackSource"
            :loading="item.data.loading"
            :alt="item.data.alt"
            :data-id="item.id"
            class="am-vehicle-player-gallery-stage__item"
          ></am-vehicle-player-image-stage>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageQualityParams } from './../../../../../../../../utils/image'
import AmVehiclePlayerImageStage from './components/image/vehicle-player-image-stage.vue'

export default {
  name: 'AmVehiclePlayerGalleryStage',

  components: {
    AmVehiclePlayerImageStage
  },

  model: {
    event: 'change',
    prop: 'value'
  },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      resizeObserverInstance: null,
      intersectionObserverInstance: null,
      width: null,
      selectedItemId: null,
      timer: null,
      isDragging: false,
      dragStartX: 0,
      scrollLeft: 0
    }
  },

  computed: {
    classes() {
      return {
        'am-vehicle-player-gallery-stage--dragging': this.isDragging
      }
    },
    enrichedItems() {
      return this.items.map((item, index) => {
        let resolvedItem

        if (item.type === 'gallery' && item.data.type === 'image') {
          let sources = []
          let fallbackSource = null

          if (item.data.source.length > 0) {
            const media = {
              type: item.data.origin,
              normal: item.data.source
            }

            sources = [
              {
                media: '(min-width: 1281px)',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 900),
                  this.getSourceByMediaAndWidth(media, 1800) + ' 2x'
                ].join(', ')
              },
              {
                media: '(min-width: 1280)',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 1280),
                  this.getSourceByMediaAndWidth(media, 2560) + ' 2x'
                ].join(', ')
              },
              {
                media: '(min-width: 900px)',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 900),
                  this.getSourceByMediaAndWidth(media, 1800) + ' 2x'
                ].join(', ')
              },
              {
                media: '(min-width: 768px)',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 768),
                  this.getSourceByMediaAndWidth(media, 1536) + ' 2x'
                ].join(', ')
              },
              {
                media: '(min-width: 600px)',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 600),
                  this.getSourceByMediaAndWidth(media, 1200) + ' 2x'
                ].join(', ')
              },
              {
                media: 'all',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 480),
                  this.getSourceByMediaAndWidth(media, 960) + ' 2x'
                ].join(', ')
              }
            ]

            fallbackSource = sources[2].srcset
          }

          resolvedItem = Object.assign({}, item, {
            data: Object.assign({}, item.data, {
              sources,
              fallbackSource,
              loading: index === 0 ? null : 'lazy'
            })
          })
          // .source can be removed later
        } else {
          resolvedItem = item
        }

        return resolvedItem
      })
    }
  },

  watch: {
    value() {
      // TODO: needed?
      this.debounce(this.scrollToActiveItem, 250)
    }
  },

  mounted() {
    this.initResizeObserver()
    this.initIntersectionObserver()
    this.addMouseListener()
  },

  beforeDestroy() {
    this.resizeObserverInstance && this.resizeObserverInstance.disconnect()
    this.intersectionObserverInstance &&
      this.intersectionObserverInstance.disconnect()
    this.removeMouseListener()
  },

  methods: {
    getSourceByMediaAndWidth(media, width) {
      let src = null

      if (media.type === 'cpo') {
        const splittedSegments = media.normal.split('/')
        const filename = splittedSegments.pop()
        src = splittedSegments.join('/') + '/tr:w-' + width + '/' + filename
      } else {
        src = media.normal
      }

      return src
    },
    addMouseListener() {
      const slider = this.$refs.items
      slider.addEventListener('mousedown', this.mouseDown)
      slider.addEventListener('mouseleave', this.mouseLeave)
      slider.addEventListener('mouseup', this.mouseUp)
      slider.addEventListener('mousemove', this.mouseMove)
    },
    removeMouseListener() {
      const slider = this.$refs.items
      slider.removeEventListener('mousedown', this.mouseDown)
      slider.removeEventListener('mouseleave', this.mouseLeave)
      slider.removeEventListener('mouseup', this.mouseUp)
      slider.removeEventListener('mousemove', this.mouseMove)
    },
    debounce(f, delay) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => f.apply(this), delay)
    },
    initResizeObserver() {
      this.resizeObserverInstance = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            const width = this.$el.offsetWidth
            if (width > this.width) {
              this.width = width
            }
          }
        }
      })

      this.resizeObserverInstance.observe(this.$el)
    },
    initIntersectionObserver() {
      const options = {
        root: this.$refs.boundary,
        threshold: 0.5
      }

      this.intersectionObserverInstance = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.selectedItemId = entry.target.dataset.id
            this.$emit('change', entry.target.dataset.id)
          }
        })
      }, options)

      this.$refs.item.forEach(itemRef => {
        this.intersectionObserverInstance.observe(itemRef.$el)
      })
    },
    getResolvedImageSource(source, width) {
      const { pixelRatio } = getImageQualityParams()
      const splittedSegments = source.split('/')
      const filename = splittedSegments.pop()
      const resolvedSource =
        splittedSegments.join('/') +
        '/tr:w-' +
        width * pixelRatio +
        ',h-' +
        ((width * 2) / 3) * pixelRatio +
        ',fo-auto/' +
        filename

      return resolvedSource
    },
    scrollToActiveItem() {
      if (this.value !== this.selectedItemId) {
        const activeIndex = this.items.findIndex(item => item.id === this.value)
        this.$refs.items.scrollTo({
          left: activeIndex * this.$refs.boundary.offsetWidth,
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    mouseDown(e) {
      const slider = this.$refs.items
      this.isDragging = true
      this.dragStartX = e.pageX - slider.offsetLeft
      this.scrollLeft = slider.scrollLeft
    },
    mouseLeave() {
      this.isDragging = false
    },
    mouseUp() {
      this.isDragging = false
    },
    mouseMove(e) {
      if (this.isDragging) {
        e.preventDefault()
        const slider = this.$refs.items
        const left =
          this.scrollLeft - (e.pageX - slider.offsetLeft - this.dragStartX)
        slider.scrollTo({
          left,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>
